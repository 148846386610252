import React from "react";

// v2 Added:
// Component isntead of HOC
// remove deprecated react methods
// fixed bugs

class Dismissible2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }

  handleEvent = e => {
    const ESC = e.type === "keyup" && e.keyCode === 27;

    const clickOutside =
      this.wrapper.current &&
      e.type === "click" &&
      !this.wrapper.current.contains(e.target);

    if (ESC || clickOutside) {
      this.props.onClose && this.props.onClose(e);
    }
  };

  componentDidMount = () => {
    document.addEventListener("click", this.handleEvent);
    document.addEventListener("keyup", this.handleEvent);
  };

  componentWillUnmount = () => {
    document.removeEventListener("click", this.handleEvent);
    document.removeEventListener("keyup", this.handleEvent);
  };

  render() {
    return <div ref={this.wrapper}>{this.props.children}</div>;
  }
}

export default Dismissible2;
