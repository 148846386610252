import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  selectCompanyByListingId,
  selectCompanyById
} from "./../../redux/selectors";

import Image from "../Image";

const Logo = styled(Image)`
  display: block;
  width: ${props => props.width};
  height: ${props => props.height};
`;

const onError = e => {
  e.target.src = `${process.env.REACT_APP_SITE_ASSETS}company_blank_image.jpg`;
};

export const CompanyLogo = ({ company, companyId, size = 72 }) => {
  return company || companyId ? (
    <Logo
      src={`https://img.voxmarkets.co.uk/72/companies/${
        company ? company.id : companyId
      }.png`}
      alt={company ? company.companyName : null}
      onError={onError}
      width={`${size}px`}
      height={`${size}px`}
    />
  ) : null;
};

CompanyLogo.propTypes = {
  companyId: PropTypes.string.isRequired,
  size: PropTypes.number
};

const mapStateToProps = (state, { companyId, listingId }) => ({
  company: listingId
    ? selectCompanyByListingId(state, listingId)
    : selectCompanyById(state, companyId)
});

export default connect(mapStateToProps)(CompanyLogo);
