import React from "react";
import styled from "styled-components";

import Text from "./../Text";
import { Spacing } from "./../Layout";
import { isTradingClose } from "./../../utils";

import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";

import { formatNumber } from "./../../utils";
import DateFormatter from "../../utils/Date";

import withQuotesUpdates from "./../withQuotesUpdates";
import {
  selectIsAuthenticationCompleted,
  selectIsLivePrices
} from "./../../redux/selectors";

const Price = styled.div`
  text-align: right;
`;

const lastUpdateTime = snapshot => {
  const now = Date.now();
  const isExpired = snapshot.updateTime * 1000 < now - (now % 86400000);
  const date = snapshot.updateTime ? new DateFormatter(snapshot.updateTime * 1000) : null;
    if( !date ){
    return isTradingClose(snapshot.tradingPhase)
         ? "At close"
         : "--:--"
  }
  if( isExpired ){
    return `Last updated ${date.formatDate()}`;
  }
  return isTradingClose(snapshot.tradingPhase)
       ? "At close"
       : `Last updated ${date.formatTime()}`
  
}

const HeaderPrice = ({ snapshot, whiteText }) => {
  const displayPrice = isTradingClose(snapshot.tradingPhase)
    ? snapshot.displayPrice
    : snapshot.mid;
  return (
    <>
      <Price>
        <div>
          <Text as="span" white={whiteText} size={0.78}>
            {snapshot.curCode}
          </Text>{" "}
          <Text as="span" white={whiteText} bolder size={1.55}>
            {formatNumber(displayPrice, snapshot.decimalPlaces)}
          </Text>
        </div>
        <Text
          bold
          positive={snapshot.pctChange > 0}
          negative={snapshot.pctChange < 0}
          white={snapshot.pctChange === 0 && whiteText}
        >
          {formatNumber(snapshot.change, snapshot.decimalPlaces)} (
          {formatNumber(snapshot.pctChange, 2)}%)
        </Text>
        <Spacing top={0.2}>
          <Text white={whiteText} size={0.67}>
            {lastUpdateTime(snapshot)}
          </Text>
        </Spacing>
      </Price>
    </>
  );
};

const HeaderPriceContainer = ({
  fetchPrices,
  listingId,
  snapshot,
  authenticationCompleted,
  userHasLivePrices,
  ...props
}) => {
  React.useEffect(() => {
    if (authenticationCompleted) {
      fetchPrices(listingId, userHasLivePrices);
    }
  }, [listingId, authenticationCompleted]);

  return authenticationCompleted && snapshot ? (
    <HeaderPrice snapshot={snapshot} whiteText={props.whiteText} />
  ) : null;
};

const mapStateToProps = (state, props) => {
  return {
    authenticationCompleted: selectIsAuthenticationCompleted(state),
    userHasLivePrices: selectIsLivePrices(state),
    snapshot: state.quotes[props.listingId]
  };
};

const mapDispatchToProps = {
  fetchPrices: (listingId, showLivePrices) =>
    Actions.quotes.fetchQuotes([listingId], showLivePrices)
};

export default withQuotesUpdates(
  connect(mapStateToProps, mapDispatchToProps)(HeaderPriceContainer)
);
