import { Component } from "react";
import { withRouter } from "react-router";

class ScrollToTop extends Component {
  componentDidMount() {
    this.scroll();
  }

  scroll = () => {
    if (
      this.props.history.location.state &&
      this.props.history.location.state.noScroll === true
    )
      return false;

    window.scrollTo(0, 0);
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.scroll();
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
