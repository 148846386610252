import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { Grid, Cell } from "./Layout";
import PostAuthor from "../containers/PostHeader";
import Reactions from "./Reactions";
import Text from "./Text";
import { Link, Loader } from "./Utils";
import AttachmentPreview from "../containers/Attachment";

const ListContainer = styled.div`
  margin: 1rem 0 0;
  ${props =>
    props.overflow
      ? `
max-height: ${props.overflow}px;
  overflow-y: auto;`
      : ``};
`;

const CommentContent = styled(props => {
  return props.permalink ? (
    <Link href={props.permalink} blank>
      <div className={props.className}>
        <Text size={0.85}>{props.content || props.postContent}</Text>
      </div>
      <AttachmentPreview content={props.content || props.postContent} />
    </Link>
  ) : (
    <div>
      <div className={props.className}>
        <Text size={0.85}>{props.content || props.postContent}</Text>
      </div>
      <AttachmentPreview content={props.content || props.postContent} />
    </div>
  );
})`
  background: ${props => props.theme.color.light};
  padding: 8px;
  border-radius: 4px;
  position: relative;
  min-height: 3rem;
  word-break: break-word;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 33%;
    left: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 12px 8px 0;
    border-color: transparent ${props => props.theme.color.light} transparent
      transparent;
  }
`;

export const Comment = styled(props => {
  return (
    <Grid className={props.className}>
      <Cell size="2">
        <PostAuthor userId={props.source} vertical size={30} />
      </Cell>
      <Cell size="10">
        <CommentContent {...props} />

        <Grid className="comment-reactions">
          <Text size={0.8} light>
            {format(new Date(props.createdAt), "dd/MM/yyyy, HH:mm")}
          </Text>
          <Reactions itemId={props.id} compact alignRight />
        </Grid>
      </Cell>
    </Grid>
  );
})`
  margin: 8px 0;
  color: ${props => props.theme.color.text};
  ${Grid}.comment-reactions {
    margin: 5px 0;
    justify-content: space-between;
    padding-left: 5px;
  }
`;

const Cta = props => <Text size={1.1}>Be the first to comment!</Text>;

export default class CommentList extends React.Component {
  constructor(props) {
    super(props);
    this._list = React.createRef();
  }
  componentDidMount() {
    this._list.current.scrollTop = this._list.current.scrollHeight;
  }
  componentDidUpdate(prevProps) {
    if (
      (!prevProps.comments && this.props.comments) ||
      prevProps.comments.length !== this.props.comments.length
    ) {
      this._list.current.scrollTop = this._list.current.scrollHeight;
    }
  }
  render() {
    return (
      <ListContainer
        ref={this._list}
        overflow={this.props.loading ? null : this.props.overflow}
      >
        {this.props.comments &&
          this.props.comments.map(comment => <Comment {...comment} />)}
        {!this.props.loading && this.props.comments.length === 0 && <Cta />}
        {this.props.loading && <Loader size={25} />}
      </ListContainer>
    );
  }
}
