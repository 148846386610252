import React from "react";
import Underlay from "./Underlay";
import Layout from "./PageLayout";
import Modal from "./Modal2";

export default function(props) {
  const renderPage = props => {
    return <Layout watchlist={true}>{/*wip*/}</Layout>;
  };

  const Parent = props =>
    props.underlay ? (
      <Underlay
        {...props}
        Page={props.Page || renderPage}
        iconColor="#fff"
      />
    ) : (
      <Modal {...props} iconColor="#fff" />
    );
  const { onClose, children, width } = props;
  return (
    <Parent width={width || 60} onClose={onClose}>
      {children}
    </Parent>
  );
}
