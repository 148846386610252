const filters = [
  {
    "label":"All types",
    "categories":"!NAV,!GEO,!PFU,!TAV,!TVR"
  },
  {
    "label":"Results and trading updates",
    "slug": "results-trading-updates",
    "categories":"QRF,QRT,AGM,ACS,FR,IR,TST"
  },
  {
    "label":"Operational updates",
    "slug": "operational-updates",
    "categories":"REP,UPD,DRL,CAN"
},
{
  "label":"Product launches, updates and contract wins",
  "slug" : "product-launches-contracts",
  "categories":"PRL,CNT,RAP,REA,RES"
},
  {
    "label":"Mergers, acquisitions, disposals and JVs",
    "slug": "mergers-acquisitions-disposals",
    "categories":"ACQ,DIS,TAB,FEE,FEO,FER,LOI,MER,OFB,OFF,OLA,ORE,OTT,OUP,PNM,JVE,ALL,AGR,SOA,STC,ODP,RSP,OFD,RTT"
},
  {
    "label":"New issues, fundraisings, buybacks and dividends",
    "slug" : "new-issues-raising-buybacks-dividends",
    "categories":"IOD,IOE,RTE,ROI,ARI,ALS,TEN,LIS,ITF,CAS,POS,DIV,PDI,PSP,PFT"
  },
  {
    "label":"Financial calendar",
    "slug" : "financial-calendar",
    "categories":"NAR,NOA,NOG,NOR"
  },
  {
    "label":"Holdings",
    "slug" : "holdings",
    "categories":"DCC,RET,HOL"
  },
  {
    "label":"Management changes and director dealing",
    "slug": "director-dealings-management-updates",
    "categories":"COS,RDN,BOA,DSH"
  },
  {
    "label":"Funds and investment trusts",
    "slug": "funds-and-trusts",
    "categories":"NAV,GEO,PFU,TAV"
  },
  {
    "label":"General",
    "slug": "general",
    "categories":"SUS,SRS,REN,NOT,MSCU,CAR,CON,GMS,FUR,SPC,SPM,STR,RAG,ROM,TVR,SYR,PGR,IRS,MSCH,BRC,BLR,CMC,RC,TRS,MSC,MSCM,CIR,DOC,MSCL,TSM,NEX"
  }
]


export default filters;
