import React from "react";
import styled from "styled-components";
import { DocumentPDF } from "./Icons";
import Text from "./Text";

const Link = styled.a`
    background-image:url("${DocumentPDF}");
    background-position:5px 50%;
    background-size:20px auto;
    background-repeat:no-repeat;
    padding:8px 8px 8px 30px;
    margin:0.5rem 0;
    cursor:pointer;
    outline:none;
${ Text } {
display: inline-block;
}
`;

const DocumentLink = ({ displayName, type = "pdf", url, ...props }) => {
  return (
    <Link target="_blank" href={url} {...props}>
      <Text size={0.9} dark>
        {displayName}
      </Text>
    </Link>
  );
};

export default DocumentLink;
