import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: auto;
    background-color: #fff !important;
  }
`;

export function IFrameLayout({ children }) {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  );
}
