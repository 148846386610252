import React from "react";
import ReactDOM from "react-dom";

const Dismissible = Component => {
  return class Dismissible extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        visible: props.visible || false
      };
    }

    hide = e => {
      if (this.state.visible) {
        if (!this.ref) {
          this.ref = ReactDOM.findDOMNode(this);
        }
        let ESC = e.type === "keyup" && e.keyCode === 27;
        let clickOutside =
          e.type === "click" &&
          e.target !== this.ref &&
          !this.ref.contains(e.target);

        if (ESC || clickOutside) {
          this.props.onClose
            ? this.props.onClose(e)
            : this.setState({ visible: false });
        }
      }
    };

    componentWillReceiveProps = nextProps => {
      if ("visible" in nextProps) {
        this.setState({
          visible: nextProps.visible
        });
      }
    };

    componentDidMount = () => {
      if (typeof window !== "undefined") {
        window.requestAnimationFrame(() => {
          this.ref = ReactDOM.findDOMNode(this);
          document.body.addEventListener("click", this.hide);
          document.body.addEventListener("keyup", this.hide);
        });
      }
    };

    componentWillUnmount = () => {
      document.body.removeEventListener("click", this.hide);
      document.body.removeEventListener("keyup", this.hide);
    };

    render() {
      return this.state.visible ? <Component {...this.props} /> : <div />;
    }
  };
};

export default Dismissible;
