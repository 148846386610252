import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

const Link = ({ block, ...props }) => <a {...props} />;

const LinkSwitch = ({ href, isRouter, blank, overlay, dispatch, ...rest }) => {
  if(href && href.indexOf('mailto:') === 0){
    return <Link href={href} {...rest} />;
  }
  if (!blank && isRouter && href && href.indexOf("http") !== 0) {
    let linkTarget = { pathname: href };
    if (typeof window !== "undefined" && overlay) {
      linkTarget.state = { overlayContext: window.location.pathname };
    }
    return <RouterLink to={linkTarget} {...rest} />;
  }
  let target;
  if (blank || rest.hasOwnProperty("target")) {
    target = "_blank";
  }
  return <Link href={href} {...rest} target={target} />;
};

const mapStateToProps = state => {
  return {
    isRouter: state.ui.isRouter
  };
};
const LinkRouter = connect(mapStateToProps)(LinkSwitch);

export default styled(LinkRouter)`
  font-family: ${props => props.theme.font.default};
  color: ${props => props.theme.color.text};
  text-decoration: ${props => (props.underline ? "underline" : "none")};
  ${props => props.block && `display:block`};
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

export const A = styled(RouterLink)`
  text-decoration: none;
  outline: none;
  color: inherit;
`;
