import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { PlusThin, Tick, Bell } from "./Icons";
import { Text } from "./Typography";

const Wrapper = styled.button`
  white-space: nowrap;
  user-select: none;
  vertical-align: middle;
  border: none;
  /* width: 67px; */
  background-color: ${(props) => props.color || "#135d80"};
  display: inline-flex;
  align-items: center;
  border-radius: 2px;
  padding: 0 4px;
  height: 22px;
  cursor: pointer;

  svg + div,
  svg + div + div {
    margin-left: 3.5px;
  }

  .follow-hover-text,
  .follow-text {
    text-align: center;
    flex: 1 1 0%;
    flex-basis: 0%;
    display: none;
    width: 43px;
  }
  .follow-text {
    display: block;
  }
  :active {
    transform: translateY(1px);
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: #009148;
  }

  ${(props) =>
    props.hover &&
    css`
      &:hover {
        background-color: ${(props) => props.hoverColor || "#1d71a9"};
        .follow-text {
          display: none;
        }
        .follow-hover-text {
          display: block;
        }
      }
    `};
`;

export const FButton = (props) => (
  <Wrapper
    onClick={props.onClick}
    color={props.color}
    hover={props.hover}
    hoverColor={props.hoverColor}
  >
    {props.icon}
    {props.children && (
      <Text white size={0.611} className="follow-text">
        {props.children}
      </Text>
    )}

    {props.hoverText && (
      <Text white size={0.611} className="follow-hover-text">
        {props.hoverText}
      </Text>
    )}
  </Wrapper>
);

FButton.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.string,
  color: PropTypes.string,
  hover: PropTypes.bool,
  hoverText: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
};

export const StateButton = (props) => (
  <FButton
    icon={props.hideIcon ? null : props.isFollowing ? <Tick /> : <PlusThin />}
    onClick={props.onClick}
    color={props.isFollowing ? "#135d80" : "#008040"}
    hover={props.isFollowing}
    hoverText={props.hoverText || "Unfollow"}
    hoverColor={props.hoverColor || "#1d71a9"}
  >
    {props.isFollowing ? "Following" : "Follow"}
  </FButton>
);

StateButton.propTypes = {
  isFollowing: PropTypes.bool,
  hover: PropTypes.bool,
  hoverText: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
  hideIcon: PropTypes.bool,
};

export const BellButton = (props) => (
  <FButton
    icon={<Bell scale={0.65} />}
    onClick={props.onClick}
    hoverColor={props.hoverColor || "#1d71a9"}
  />
);
