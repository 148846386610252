import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link, Img } from "../Utils";
import Text from "../Text";
import CompanyPageLink from "../CompanyPageLink";
import * as Selectors from "./../../redux/selectors";

const SearchResultItem = styled(Link)`
display:flex;
align-items:center;
  font-size: 18px;
background-color:${props => props.theme.color.light};
padding : 4px 0 4px 4px
font-family: ${props => props.theme.font.default};
border-top: 1px solid ${props => props.theme.color.border};
&:hover {
background-color:${props => props.theme.color.main}
}



`;

const Header = styled(Text)`
display:flex;
align-items:center;
font-size: 18px; 
font-weight: bold;
background-color: white;
color: ${props => props.theme.color.text};
padding : 8px;
border-top: 1px solid ${props => props.theme.color.border};
`;

const Avatar = styled(Img)`
  display: inline-block;
  width: 45px;
  height: 45px;
  flex-basis: 45px;
`;

const Title = styled(Text)`
  padding-left: 5px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.bold && "font-weight: 900;"};
  ${props => props.expired && "color: #666;"}
  & ${Text} {
    color: inherit;
  }
`;

const CompanyTitle_ = props => {
  let title = props.listingId ? props.ticker : props.name;
  let subtitle = props.companyId ? props.ticker : props.name;
  return (
    <>
      <CompanyAvatar
        src={props.image}
        placeholder={`${
          process.env.REACT_APP_SITE_ASSETS
        }company_blank_image.jpg`}
      />
      <Title bold expired={props.expired}>
        {title} {subtitle && <Text size={0.75}>{subtitle}</Text>}
      </Title>
    </>
  );
};

const CompanyAvatar = styled(Avatar)`
  padding: 4px;
`;

const CompanyTitle = connect(
  (state, { listingId, companyId, name, ticker, image, expired }) => {
     name =
      name || companyId
      ? Selectors.selectCompanyById(state, companyId).companyName
      : "";
    image =
      image || companyId
      ? `https://img.voxmarkets.co.uk/50/companies/${companyId}.png`
      : "";
    if (name === "" && listingId) {
      let company = Selectors.selectCompanyByListingId(state, listingId);
      if (company) {
        name = company.companyName;
        image =
          image ||
          `https://img.voxmarkets.co.uk/50/companies/${company.id}.png`;
      }
    }
    ticker =
      ticker || listingId
        ? Selectors.selectListingById(state, listingId).listingTicker
        : "";
    expired = 
      expired || listingId
      ? !!Selectors.selectListingById(state, listingId).listingEndDate
      : "";
    return {
      name,
      ticker,
      image,
      expired,
    };
  }
)(CompanyTitle_);

const SearchResultCompany = ({ listingId, companyId, listing, company }) => {
  return (
    <CompanyPageLink companyId={companyId} listingId={listingId}>
      <SearchResultItem>
        <CompanyTitle listingId={listingId} companyId={companyId} />
      </SearchResultItem>
    </CompanyPageLink>
  );
}


export const SearchResult = ({ category, listingId, companyId, user }) => {
  switch (category) {
    case "listing":
      return <SearchResultCompany listingId={listingId} />;
    case "company":
      return <SearchResultCompany companyId={companyId} />;
    default:
      return null;
  }
};

export const SearchResultHeader = ({ title }) => {
  return <Header>{title}</Header>;
};
