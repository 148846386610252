import React from "react";
import styled from "styled-components";
import { Verify } from "../Icons";
import { Img } from "../Utils";

const Verified = styled.div`
  position: absolute;
  top: 85%;
  left: 85%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  display: inline-block;
  width: ${props => props.size};
  height: ${props => props.size};
  position: relative;
  flex-shrink: 0;
  img {
    width: 100%;
    border-radius: 50%;
    display: inline-block;
    background-color: ${props => props.theme.color.main};
  }
`;

const defaultProps = {
  size: "40px"
};

const Avatar = props => (
  <Wrapper size={props.size}>
    <Img
      src={props.src}
      placeholder="https://www.voxmarkets.co.uk/wp-content/themes/voxmarkets3/images/avatar_default.png"
      width={props.size}
      height={props.size}
    />
    {props.verified && (
      <Verified>
        <Verify />
      </Verified>
    )}
  </Wrapper>
);

Avatar.defaultProps = defaultProps;

export default Avatar;
