import React from "react";
import { connect } from "react-redux";

import RealtimeData from "../utils/RealtimeData";
import { selectLogedUserId } from "../redux/selectors";

const withQuotesUpdatesFn = Component =>
  connect(state => ({
    loggedUserId: selectLogedUserId(state)
  }))(props => {
    React.useEffect(() => {
      if (props.listingId && props.loggedUserId) {
        RealtimeData.getInstance().subscribeToPrices(props.listingId);
        return () =>
          RealtimeData.getInstance().unsubscribeFromPrices(props.listingId);
      }
    }, [props.listingId, props.loggedUserId]);

    return <Component {...props} />;
  });

export default withQuotesUpdatesFn;
