import React, { useState } from "react";
import { Grid, Cell } from "./Layout";
import Reactions from "./Reactions";
import ShareMenu from "../containers/ShareMenu";

export default function Interactions({
  id,
  scope,
  shareText,
  url,
}) {

  let itemId = id;
  if (scope && scope.hasOwnProperty("storyId")) {
    itemId = scope.storyId;
  }
  return (
    <div>
      <Grid>
        <Cell fill>
          <ShareMenu size={30} scope={scope} shareText={shareText} url={url} />
        </Cell>
        <Cell fit />
        <Cell fit>
          <Reactions itemId={itemId} />
        </Cell>
      </Grid>
    </div>
  );
}
