export async function getReport(name) {
  return (
    await fetch(
      `https://api.voxmarkets.co.uk/v2/statistics/publicReports/?filter[where][report]=${name}`
    )
  ).json();
}

export async function getMarketMovers(indexId) {
  return (
    await fetch(
      `https://api.voxmarkets.co.uk/v2/delayed/marketMovers?filter[where][collectionId]=${indexId}`
    )
  ).json();
}
