import React from "react";
import { ThemeProvider } from "styled-components";

import VoxTheme from "./VoxTheme";

const VoxThemeProvider = props => (
  <ThemeProvider theme={VoxTheme}>{props.children}</ThemeProvider>
);

export default VoxThemeProvider;
