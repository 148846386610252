import React from "react";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";

import Link from "./Link";

const mapStateToProps = (state, { id }) => ({
  index: state.indexes.indexesById[id]
});

const mapDispatchToProps = dispatch => ({
  getIndex: id => dispatch(Actions.indexes.fetchIndexesByfilter({ id }))
});

class LinkContainer extends React.Component {
  componentDidMount() {
    !this.props.index && this.props.getIndex(this.props.id);
  }

  shouldComponentUpdate(nextProps) {
    return (
      (!this.props.index && nextProps.index) ||
      (this.props.index && nextProps.index.id !== this.props.index.id)
    );
  }

  render() {
      const { index, ...props } = this.props;
    return this.props.index ? (
            <Link href={`/index/${index.indexLeafname}/`} {...props}>
        {index.indexName}
      </Link>
    ) : null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
