import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import UiActions from "./../../redux/actions";
import * as Select from "./../../redux/selectors";
import { Grid, Cell, Spacing } from "../Layout";
import { Text } from "../Typography";
import WithCompanyIds from "../withCompanyIds";
import PrivateInvestor from "../PrivateInvestor";
import { ShareMenu } from "../ShareButton";
import Reactions from "./../Reactions";
import { format } from "date-fns";
import Badge from "../../components/Company/CompanyBadge";
import { track } from "../../utils/analytics";

const countFormat = array => {
  if (array && array.length) {
    return array.length >= 100 ? "99+" : array.length;
  }
  return "";
};


//---------------------------------
//---------------------------------
//---------------------------------

const PDFLink = styled.a`  
    padding: 0px 6px;
    outline: none;
    color: #fff;
    background-color: #135d80;
    border: 1px solid #135d80;
    border-radius: 4px !important;   
    font-family: "Roboto Condensed", sans-serif !important;
    font-size:0.7389rem;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    cursor: pointer; 
    height: 30px;
    text-decoration: none;
    width:100%;
    &:hover {
      background-color: #166d96;
      border-color: #1a7dac;
    }
  }
`;

class DownloadPdf_ extends PureComponent {
  handlePDFClick = e => {
    if (!this.props.loggedIn) {
      e.preventDefault();
      this.props.showLoginOverlay();
    } else {
      this.props.onDownload && this.props.onDownload();
    }
  };
  render() {
    const { pdfUri, loggedIn } = this.props;
    return (
      <PDFLink href={loggedIn ? pdfUri : "#"} onClick={this.handlePDFClick}>
        Download PDF
      </PDFLink>
    );
  }
}

const DownloadPdf = connect(
  state => ({
    loggedIn: Select.loggedIn(state)
  }),
  {
    showLoginOverlay: () => UiActions.loginOverlay.show(true)
  }
)(DownloadPdf_);

//---------------------------
//---------------------------
//---------------------------

class RNSReactions extends PureComponent {
  handleDownload = () => {
    track("news", {  action: "download-pdf", storyId: this.props.storyId });
  };
  render() {
    const { pdfUri, storyId, twitterShareText, id, storyHeadline } = this.props;
    return (
      <Grid style={{alignItems:"center", justifyContent:"flex-end"}}>
        <Cell fit>
          <ShareMenu
            size={40}
            twitterText={twitterShareText}
            text={storyHeadline}
            hashtags={['investing','shares','RNS']}
            link={`https://www.voxmarkets.co.uk/rns/announcement/${storyId}`}
          />
        </Cell>

        <Cell fit>
          <Reactions itemId={storyId} />
        </Cell>
      </Grid>
    );
}
}

//--------------------------------------------------
//--------------------------------------------------
//--------------------------------------------------
const RNSHeaderWrapper = styled.header`
  background: url("https://s3-eu-west-1.amazonaws.com/vox.store.images/user-cover/profile-bg-2.jpg")
    50% 50% / cover;
  > div {
    padding: 3rem 0.5rem 0.5rem;
    display: inline-block;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    @media (min-width: 480px) {
      padding-top: 0.5rem;
    }
  }
`;

const HeaderBadge = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;  
  @media (min-width: 480px) {
    max-width: 360px;
    }
  }
`;

const RNSHeader = ({ metaData, companyId, listingId }) => {
  return (
    <RNSHeaderWrapper>
      <div>
        {companyId &&
         listingId && (
           <HeaderBadge>
             <Badge listingId={listingId} companyId={companyId} whiteText />
           </HeaderBadge>
        )}

        <Spacing vertical={1}>
          <Text white size={1.8} center>
            {metaData.storyHeadline}
          </Text>
          <Text white light size={1.1} center>
            {format(
              new Date(metaData.timestampSeconds * 1000),
              "do MMMM yyyy, HH:mm"
            )}
          </Text>
        </Spacing>
      </div>
      <div>
      <RNSReactions
        pdfUri={metaData.pdfUri}
        storyHeadline={metaData.storyHeadline}
        storyId={metaData.storyId}
        id={metaData.id}
        twitterShareText={metaData.twitterShareText}
      />
      </div>
    </RNSHeaderWrapper>
  );
};
//-----------------------------------

const MentionsBadge = styled.div`
  background-color: rgb(224, 231, 236);
  padding: 0.5rem;
`;

const CompanyCell = props => (
  <Cell mdCol={6} lgCol={4} key={props.tickerShort} spacing={1}>
    <MentionsBadge>
      <Badge listingId={props.listingId} companyId={props.companyId} />
    </MentionsBadge>
  </Cell>
);
const CompanyBadgeCell = WithCompanyIds(CompanyCell);

const RNSFooterComponent = ({
  mentions,
  storyId,
  storyHeadline,
  className,
  pdfUri,
  companyId,
  ...props
}) => (
  <div className={className}>
    {mentions.length > 0 && (
      <div>
        <Text bold size={1.2}>
          Companies mentioned in this announcement
        </Text>
        <Spacing top={1}>
          <Grid>
            {mentions.map(mention => (
              <CompanyBadgeCell
                key={mention.ticker}
                tickerShort={mention.ticker}
              />
            ))}
          </Grid>
        </Spacing>
      </div>
    )}
  </div>
);

const RNSFooter = styled(RNSFooterComponent)`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
`;


//--------------------------------------------------------

const Wrapper = styled.div`
  border: 4px solid #aabecb;
  border-radius: 3px;
  @media print {
  position:absolute;
  top:5cm;
  }
`;

class Announcement extends PureComponent {
  render() {
    const { listingId, companyId, meta } = this.props;
    return (
      <Wrapper>
        <RNSHeader
          companyId={companyId}
          listingId={listingId}
          metaData={meta}
        />

        <Spacing all={0.5}>
          
          <Spacing vertical={0.5}>
            <PrivateInvestor storyId={meta.storyId} />
          </Spacing>
          <RNSReactions
            pdfUri={meta.pdfUri}
            storyHeadline={meta.storyHeadline}
            storyId={meta.storyId}
            id={meta.id}
            twitterShareText={meta.twitterShareText}
          />
        </Spacing>
      </Wrapper>
    );
  }
}

export default Announcement;
