import React from "react";
import styled from "styled-components";
import { decodeHTML } from "./../utils/";
import { Img, Link } from "./Utils";
import { Grid, Cell } from "./Layout";
import Text from "./Text";
import ListingBadgePopover from "./ListingBadgePopover";
import withListingIds from "./withListingIds";
import CompanyPageLink from "./CompanyPageLink";

const BadgeForCompanyId = withListingIds(ListingBadgePopover);

const Avatar = styled(
  ({ avatar, displayName, className, url, size = 50, ...props }) => (
    <Grid className={className}>
      <Cell fit>
        {avatar && <Img src={avatar} width={size} height={size} />}
      </Cell>
      <Cell fill>
        <Text as="span" bold size={size < 50 ? 0.75 : 0.9} white={props.light}>
          {decodeHTML(displayName)}
        </Text>
        {props.children}
      </Cell>
    </Grid>
  )
)`
  align-items: center;
  ${Img} {
    background: #eee;
    border-radius: ${(props) => (props.round ? "100%" : "0")};
    border: 1px solid #fff;
    display: block;
  }
  ${(props) => props.vertical && "flex-direction:column; text-align:center"};
`;

export default function AvatarComponent({
  companyData,
  userData,
  entityData,
  vertical,
  ...props
}) {
  if (companyData) {
    return (
      <CompanyPageLink companyId={companyData.id}>
        <BadgeForCompanyId atHover wrapInBlock companyId={companyData.id}>
          <Avatar
            displayName={companyData.companyName}
            avatar={`https://img.voxmarkets.co.uk/100/companies/${companyData.id}.png`}
            size={props.size}
            light={props.light}
          />
        </BadgeForCompanyId>
      </CompanyPageLink>
    );
  } else if (userData) {
    return (
      <Link href={`/members/${userData.login}`}>
        <Avatar
          displayName={userData.name}
          avatar={userData.avatar || userData.avatarURI}
          round
          vertical={vertical}
          size={props.size}
          light={props.light}
          children={props.childern}
        />
      </Link>
    );
  }
  return <div style={{ height: "50px" }} />;
}
