import React from "react";
import styled from "styled-components";
import { X } from "./Icons";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 2.5rem 2rem;
  pointer-events: auto;
  background-color: #fff;
  ${props => props.onClose && "padding-top:60px"};
  border-radius: 3px;
  ${props => props.noPadding && "padding:0px"};
  outline: 0;
`;

const Close = styled.button`
  border: 0;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 1rem;
  outline: none;
`;

export const Dialog = props => {
  return (
    <Wrapper noPadding={props.noPadding} ref={props.innerRef}>
      {props.onClose && (
        <Close onClick={props.onClose}>
          <X color={props.iconColor} />
        </Close>
      )}
      {props.children}
    </Wrapper>
  );
};

export default Dialog;
