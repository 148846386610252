import React from "react";
import styled from "styled-components";
import { StateButton } from "./FollowButton";
import { Button } from "./Utils";
import Popover from "./Popover";
import Text from "./Text";

const PopoverWrapper = styled.div`
  display: inline-block;
`;

const DropdownHeader = styled.div`
  padding: 8px 3em 8px 8px;
  &:hover {
    background: transparent;
  }
  &:first-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  &:last-child {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

const FollowToggle = styled.span`
  font-family: ${props => props.theme.font.default};
  font-weight: 700;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  border: ${props => (props.isFollowing ? "none" : "1px solid #fff")};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const WatchlistToggleWrapper = styled.div`
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  + * {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
`;
const WatchlistToggle = ({ name, isFollowing, onClick }) => (
  <WatchlistToggleWrapper onClick={onClick}>
    <Text white size={0.89} bold>
      {name}
    </Text>
    <FollowToggle isFollowing={isFollowing}>
      {isFollowing ? "✓" : "+"}
    </FollowToggle>
  </WatchlistToggleWrapper>
);

const WatchlistContainer = styled.div`
  max-height: 30vh;
  overflow: auto;
`;

//---------------------
//---------------------
//---------------------

//helpers
const checkIsFollowing = (items, listingId) =>
  Object.values(items).find(item => item.listingId === listingId)
    ? true
    : false;

const selectItem = (items, watchlistId, listingId) =>
  Object.values(items).find(
    item => item.listingId === listingId && item.watchlistId === watchlistId
  );

const checkIsFollowingInWatchlist = (items, watchlistId, listingId) =>
  selectItem(items, watchlistId, listingId) ? true : false;

const toggleFollow = (items, add, remove) => (watchlistId, listingId) => {
  const item = selectItem(items, watchlistId, listingId);
  item ? remove(item.id, listingId) : add(watchlistId, listingId);
};

class CompanyFollowButton extends React.PureComponent {
  render() {
    const {
      listingId,
      watchlists,
      watchlistItems,
      loggedIn,
      hideIcon,
      addWatchlist,
      addlisting,
      removeListing,
      showLoginOverlay
    } = this.props;
    const isFollowing = checkIsFollowing(watchlistItems, listingId);
    const onFollow = toggleFollow(watchlistItems, addlisting, removeListing);
    const watchlistsArray = Object.values(watchlists);

    if (!loggedIn) {
      return (
        <StateButton
          isFollowing={false}
          onClick={e => showLoginOverlay(true)}
          hideIcon={hideIcon}
        />
      );
    }

    if (watchlistsArray.length === 0) {
      return (
        <StateButton
          isFollowing={isFollowing}
          onClick={e => {
            addWatchlist();
          }}
          hideIcon={hideIcon}
        />
      );
    }

    if (watchlistsArray.length === 1) {
      return (
        <StateButton
          isFollowing={isFollowing}
          onClick={e => {
            onFollow(watchlistsArray[0].id, listingId);
          }}
          hideIcon={hideIcon}
        />
      );
    }

    if (watchlistsArray.length > 1) {
      return (
        <PopoverWrapper>
          <Popover
            atClick
            label={
              <StateButton
                hoverColor="#8cc640"
                hoverText="Manage"
                isFollowing={isFollowing}
                hideIcon={hideIcon}
              />
            }
          >
            <DropdownHeader>
              <Text white light>
                Follow this company in a Watchlist
              </Text>
            </DropdownHeader>
            <WatchlistContainer>
              {watchlistsArray.map((watchlist, idx) => (
                <WatchlistToggle
                  key={idx}
                  name={watchlist.watchlistTitle}
                  onClick={e => onFollow(watchlist.id, listingId)}
                  isFollowing={checkIsFollowingInWatchlist(
                    watchlistItems,
                    watchlist.id,
                    listingId
                  )}
                />
              ))}
            </WatchlistContainer>
            <DropdownHeader onClick={e => addWatchlist()}>
              <Button color="#008640">Add new watchlist</Button>
            </DropdownHeader>
          </Popover>
        </PopoverWrapper>
      );
    }

    // if no watchlists and logged in
    return null;
  }
}

export default CompanyFollowButton;
