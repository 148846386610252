import React, { PureComponent } from "react";
import styled from "styled-components";
import Text from "./../Text";
import { connect } from "react-redux";
import { formatNumber } from "./../../utils";

import withQuotesUpdates from "./../withQuotesUpdates";

class HeaderPrice extends PureComponent {
  render() {
    const { snapshot, whiteText } = this.props;
    if (snapshot) {
      return (
        <>
          <div>
            <Text as="span" white={whiteText} size={0.68}>
              {snapshot.curCode}
            </Text>{" "}
            <Text as="span" white={whiteText} bolder size={1.3}>
              {formatNumber(snapshot.displayPrice, snapshot.decimalPlaces)}
            </Text>
          </div>
          <Text
            bold
            positive={snapshot.pctChange > 0}
            negative={snapshot.pctChange < 0}
            white={snapshot.pctChange === 0 && whiteText}
          >
            {formatNumber(snapshot.change, snapshot.decimalPlaces)}
          </Text>
          <Text
            bold
            positive={snapshot.pctChange > 0}
            negative={snapshot.pctChange < 0}
            white={snapshot.pctChange === 0 && whiteText}
          >
            {formatNumber(snapshot.pctChange, 2)}%
          </Text>
        </>
      );
    }
    return null;
  }
}

export default withQuotesUpdates(
  connect((state, props) => ({
    snapshot: state.quotes[props.listingId]
  }))(HeaderPrice)
);

//-----------------------
//-----------------------
//-----------------------

const CompactPriceWrapper = styled.div`
  text-align: right;
`;

class CompactPrice_ extends PureComponent {
  render() {
    const { snapshot, size = 1 } = this.props;
    if (snapshot) {
      return (
        <CompactPriceWrapper>
          <div>
            <Text size={size * 0.68} as="span">
              {snapshot.curCode}
            </Text>{" "}
            <Text bolder size={size * 1.3} as="span">
              {formatNumber(snapshot.displayPrice, snapshot.decimalPlaces)}
            </Text>
          </div>
          <Text size={size} bold>
            {formatNumber(snapshot.change, snapshot.decimalPlaces)} ({formatNumber(
              snapshot.pctChange,
              2
            )}%)
          </Text>
        </CompactPriceWrapper>
      );
    }
    return null;
  }
}

export const CompactPrice = withQuotesUpdates(
  connect((state, props) => ({
    snapshot: state.quotes[props.listingId]
  }))(CompactPrice_)
);

//-----------------------
//-----------------------
//-----------------------

class CurrentPrice_ extends PureComponent {
  render() {
    const { snapshot } = this.props;
    return snapshot
      ? formatNumber(snapshot.displayPrice, snapshot.decimalPlaces)
      : null;
  }
}

export const CurrentPrice = withQuotesUpdates(
  connect((state, props) => ({
    snapshot: state.quotes[props.listingId]
  }))(CurrentPrice_)
);

//-----------------------
//-----------------------
//-----------------------

class PricePercentChange_ extends PureComponent {
  render() {
    const { snapshot, ...props } = this.props;
    return snapshot ? (
      <Text
        {...props}
        positive={snapshot.pctChange > 0}
        negative={snapshot.pctChange < 0}
      >
        {formatNumber(snapshot.pctChange, 2)}%
      </Text>
    ) : null;
  }
}

export const PricePercentChange = withQuotesUpdates(
  connect((state, props) => ({
    snapshot: state.quotes[props.listingId]
  }))(PricePercentChange_)
);
