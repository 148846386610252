import React from "react";
import styled from "styled-components";
import { Config } from "@voxmarkets/api-sdk";
import { Button } from "./Utils";
import { Like, Dislike, Comment, GenericShare } from "./Icons";
import Theme from "./VoxTheme";
import { LinkedIn, Facebook, Twitter } from "./ShareButton";

const hoverWidth = "130px";

const shareLink = (postId, itemId, postType) => {
  const HOST = Config.get("PUBLIC_HOST");
  switch (postType) {
    case "data_rns":
      return `${HOST}/rns/announcement/${postId}`;
    case "company_squawks":
      return `${HOST}/activity/${itemId}`;
    case "activity_stream":
      return `${HOST}/activity/${postId}`;
    case "blogpost":
      return `${HOST}/?p=${postId}`;
    default:
  }
};

const ReactionButton = styled(Button)`
  && {
    height: ${props => (props.size ? props.size : 30)}px;
    padding: 0 4px;
    margin-right: 4px;
    background-color: ${props =>
      props.selected ? props.theme.color.dark : props.theme.color.main};
  }
  svg {
    margin-right: 4px;
  }
  svg:last-child {
    margin-right: 0;
  }
`;

export const LikeButton = ({ size = 30, ...props }) => {
  return (
    <ReactionButton color={Theme.color.main} size={size} {...props}>
      <Like scale={size / 30} />
      {props.count !== undefined && <span>{props.count}</span>}
    </ReactionButton>
  );
};

export const DislikeButton = ({ size = 30, ...props }) => {
  return (
    <ReactionButton color={Theme.color.main} size={size} {...props}>
      <Dislike scale={size / 30} />
      {props.count !== undefined && <span>{props.count}</span>}
    </ReactionButton>
  );
};

export const CommentButton = ({ size = 30, ...props }) => {
  return (
    <ReactionButton color={Theme.color.main} size={size} {...props}>
      <Comment scale={size / 30} />
      {props.count !== undefined && <span>{props.count}</span>}
    </ReactionButton>
  );
};

const ShareWrapper = styled.div`
  text-align: right;
  position: relative;
  > div {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    width: 0;
    overflow: hidden;
    transition: width 300ms;
    position: absolute;
    right: 30px;
    a {
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  button {
    vertical-align: middle;
  }
  &:hover > div {
    width: ${hoverWidth};
  }
`;

const LinkedInStyled = styled(LinkedIn)`
  margin-left: 0px;
  margin-right: 10px;
`;

export class ShareButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  toggleHover = () => {
    this.setState({
      hover: !this.state.hover
    });
  };

  render() {
    const props = this.props;
    const link = shareLink(props.id, props.itemId, props.postType);

    return (
      <ShareWrapper>
        <div style={{ width: this.state.hover ? hoverWidth : "" }}>
          <Twitter
            postId={props.id}
            postType={props.postType}
            size="31"
            text={props.text}
            link={link}
            target="_blank"
            onClick={e => {
              this.toggleHover();
              e.preventDefault();
              //override default
              //endpoint resolves the shareText and redirects to it
              typeof window !== "undefined" &&
                window.open(
                  `${Config.get(
                    "PUBLIC_HOST"
                  )}/new_node/squawks/redirectTweetIntent/${props.id}?type=${
                    props.postType
                  }`
                );
            }}
            svgStyle={{
              marginRight: "5px",
              marginTop: "5px"
            }}
          />
          <Facebook
            onClickCallback={() => {
              this.toggleHover();
            }}
            size="30"
            text={props.text}
            link={link}
            target="_blank"
          />
          <LinkedInStyled
            style={{
              marginLeft: "0px",
              marginRight: "10px"
            }}
            size="30"
            onClickCallback={() => {
              this.toggleHover();
            }}
            text={props.text}
            link={link}
            target="_blank"
          />
        </div>
        <Button style={{ padding: "2px" }} onClick={this.toggleHover}>
          <GenericShare width="20" />
        </Button>
      </ShareWrapper>
    );
  }
}
