import React, { PureComponent } from "react";
import Text from "./../Text";
import { connect } from "react-redux";

class CompanyTitle extends PureComponent {
  render() {
    const { company, listing, whiteText } = this.props;
    return company && listing ? (
      <>
        <Text white={whiteText} bolder nowrap size={1.2}>
          {listing.listingTicker}
        </Text>
        <Text white={whiteText} size={0.78}>
          {company.companyName}
        </Text>
      </>
    ) : null;
  }
}

const mapStateToProps = (state, props) => {
  return {
    listing: state.advCompanies.listings[props.listingId],
    company: state.advCompanies.companies[props.companyId]
  };
};

export default connect(mapStateToProps)(CompanyTitle);
