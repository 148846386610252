import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import styled from "styled-components";
import { BellButton } from "./FollowButton";
import Popover from "./Popover";
import Text from "./Text";
import {
  selectMineFollowByCompanyId,
  selectIsWatchedByCompannyId
} from "./../redux/selectors";
import { Loader } from "./Utils";
import { Spacing } from "./Layout";

const PopoverWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const DropdownHeader = styled.div`
  padding: 8px 3em 8px 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  &:hover {
    background: transparent;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const FollowToggle = styled.span`
  font-family: ${props => props.theme.font.default};
  font-weight: 700;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  border: ${props => (props.isFollowing ? "none" : "1px solid #fff")};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const WatchlistToggleWrapper = styled.div`
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
const WatchlistToggle = ({ name, isFollowing, onClick }) => (
  <WatchlistToggleWrapper onClick={onClick}>
    <Text white size={0.89} bold>
      {name}
    </Text>
    <FollowToggle isFollowing={isFollowing}>
      {isFollowing ? "✓" : "+"}
    </FollowToggle>
  </WatchlistToggleWrapper>
);

//---------------------
//---------------------
//---------------------

class CompanyNotifications_ extends React.PureComponent {
  static propTypes = {
    companyId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.timer = null;
  }

  // follows are not available instantly
  // check every 5s
  loadFollow() {
    !this.props.follow &&
      this.props.fetchCompanyFollow(this.props.companyId).then(response => {
        if (response.result.length === 0) {
          this.timer = setTimeout(() => {
            this.loadFollow();
          }, 5000);
        }
      });
  }

  componentDidMount() {
    this.loadFollow();
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyId !== prevProps.companyId) this.loadFollow();
  }

  handleEmailToggleClick = e => {
    const { follow } = this.props;
    this.props
      .update(follow.id, {
        requiresEmailUpdates: !follow.requiresEmailUpdates
      })
      .then(result => {
        this.props.addFeedback(
          !follow.requiresEmailUpdates
            ? "Added to notifications"
            : "Removed from notifications",
          "success"
        );
      });
  };

  handlePushToggleClick = e => {
    const { follow } = this.props;
    this.props
      .update(follow.id, {
        requiresPushUpdates: !follow.requiresPushUpdates
      })
      .then(result => {
        this.props.addFeedback(
          !follow.requiresPushUpdates
            ? "Added to notifications"
            : "Removed from notifications",
          "success"
        );
      });
  };

  render() {
    return (
      <div>
        <DropdownHeader>
          <Text white light>
            Send Notifications to:
          </Text>
        </DropdownHeader>

        {this.props.follow ? (
          <>
            <WatchlistToggle
              name="Email"
              onClick={this.handleEmailToggleClick}
              isFollowing={this.props.follow.requiresEmailUpdates}
            />
            <WatchlistToggle
              name="Push"
              onClick={this.handlePushToggleClick}
              isFollowing={this.props.follow.requiresPushUpdates}
            />
          </>
        ) : (
          <Spacing all={1}>
            <Loader size={30} />
          </Spacing>
        )}
      </div>
    );
  }
}

const CompanyNotifications = connect(
  (state, { companyId }) => ({
    follow: selectMineFollowByCompanyId(state, { companyId })
  }),
  {
    fetchCompanyFollow: companyId =>
      Actions.follows.fetchMineByfollowedId(companyId, "company"),
    update: (followId, fields) => Actions.follows.update(followId, fields),
    addFeedback: (text, status) => Actions.feedback.addFeedback(text, status)
  }
)(CompanyNotifications_);

//---------------------
//---------------------
//---------------------

//helpers
class CompanyNotificationsButton extends React.PureComponent {
  render() {
    return this.props.isWatched ? (
      <PopoverWrapper>
        <Popover atClick label={<BellButton />}>
          <CompanyNotifications companyId={this.props.companyId} />
        </Popover>
      </PopoverWrapper>
    ) : null;
  }
}

export default connect((state, props) => ({
  isWatched:
    selectIsWatchedByCompannyId(state, props.companyId) ||
    selectMineFollowByCompanyId(state, {
      companyId: props.companyId
    })
}))(CompanyNotificationsButton);
