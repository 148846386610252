import React from "react";
import { connect } from "react-redux";
import { Spacing } from "./../Layout";
import Text from "./../Text";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { loggedIn } from "./../../redux/selectors";
import AppActions from "./../../redux/actions";
import { X } from "./../Icons";

const Wrapper = styled.span`
  height: calc(25px + 1rem);
  display: none;
  align-items: center;
  position: fixed;
  z-index: 2;
  bottom: 1rem;
  right: 1rem;
  padding: 0.5rem;
  background-color: #135d80;
  border-radius: 22px;
  cursor: pointer;
  @media (max-width: 576px) {
    display: flex;
  }
`;

class WatchlistLink extends React.Component {
  handleClick = () => {
    const { history, location, loggedIn, showLoginOverlay } = this.props;

    if (!loggedIn) {
      showLoginOverlay(true);
    } else if (location.pathname === "/watchlist-extended") {
      history.location.state && history.location.state.goBack
        ? history.goBack()
        : history.push("/");
    } else {
      history.push("/watchlist-extended", { goBack: true });
    }
  };

  render() {
    if(!loggedIn){ 
      return null; 
    }
    return (
      <Wrapper onClick={this.handleClick}>
        <Text white size={1}>
          {this.props.location.pathname === "/watchlist-extended" ? (
            <X width={25} height={25} color="#fff" />
          ) : (
            <Spacing horizontal={0.5}>Watchlist</Spacing>
          )}
        </Text>
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      loggedIn: loggedIn(state)
    }),
    {
      showLoginOverlay: AppActions.loginOverlay.show
    }
  )(WatchlistLink)
);
