import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import loadable from '@loadable/component'

import { loadScript } from "../utils";
import RatioBox from "./RatioBox";

const VideoWrapper = styled.div`
  .jwplayer.jw-skin-vox .jw-controls {
    background: transparent;
  }
`;

const YTWrapper = styled.div`
  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
`;

const YouTubeLazy = loadable.lib(() => import("react-youtube"));
const SeeenLazy = loadable(() => import("./SeeenMediaPlayer.js"));

const propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  cover: PropTypes.string,
  jwPlatformID: PropTypes.string
};

class MediaPlayer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.divId = `${props.id}-${Math.floor(Math.random() * 10000 + 1)}`;
    this._div = React.createRef();

  }

  handlePlay = (oldState, viewable) => {
    const config = this.jwplayer.getConfig();
    this.props.onPlay &&
      this.props.onPlay({
        oldState,
        jwPlayerConfig: config,
        sharingLink: config ? config.sharing.link : null
      });
  };


  initJWPlayer = jwplayer => {
    this.jwplayer = jwplayer(this._div.current.id);

    let sharing = null;

    if (this.props.link){
      sharing = {
        sites: ["facebook", "twitter", "linkedin", "email"],
        link: `${typeof window !== "undefined" && window.location.origin}${
          this.props.link
        }`
      };
    }
    this.jwplayer.setup({
      file: this.props.src,
      image:
        this.props.cover ||
        "https://s3-eu-west-1.amazonaws.com/vox.assets.public/site-assets/cover.png",
      mediaid: this.props.jwPlatformID,
      sharing,
      intl: {
        en: {
          sharing: {
            heading: "Share this video"
          }
        }
      },
      skin: {
        name: "vox"
      },
      ga: {
        label: "mediaid"
      }
      //...audioSetup
    });

    this.jwplayer.on("play", this.handlePlay);

  };

  componentDidMount() {
    if (this.props.jwPlatformID) {
      window.jwplayer
      ? this.initJWPlayer(window.jwplayer)
        : loadScript(
            "jwplayer-EVVSco9D",
            "https://content.jwplatform.com/libraries/EVVSco9D.js"
          )
            .then(() => {
              window.jwplayer && this.initJWPlayer(window.jwplayer);
            })
            .catch(console.error);
    }
  }

  getYouTubeID = url => {
    url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  };

  isYoutube = () => this.props.src && this.props.src.includes("youtube.com");
  isAudioboom = () => this.props.mediaType === "embed/audioboom" && this.props.mediaMetadata.audioboomUrl !== undefined;
  isSeeen = () => this.props.mediaType.includes("seeen") || this.props.src && this.props.src.includes("videos.voxmarkets.co.uk");

  
  
  render() {
    return (
      <VideoWrapper>
        
        {this.isYoutube() && (
          <RatioBox ratio={ "9/16" }>
            <YouTubeLazy>
              {({ default: YouTube }) => 
                <YTWrapper>
                  <YouTube
                    videoId={this.getYouTubeID(this.props.src)}
                    opts={{
                      width: "100%",
                      height: "100%",
                      playerVars: { showinfo: 0 }
                    }}
                  />
                </YTWrapper>
              }
            </YouTubeLazy>
          </RatioBox>
        )}
        {this.isAudioboom() && (
          <RatioBox ratio={ "11/16" }>
            <iframe width="100%" height="100%" src={`https://embeds.audioboom.com/posts/${this.props.vendorsId}/embed/v4`} style={{backgroundColor:"transparent", display:"block", padding: 0, maxWidth:"100%", height:'100%', minHeight: '210px'}} frameBorder="0" allowtransparency="allowtransparency" scrolling="no"></iframe>
          </RatioBox>
          )}
        {!this.isYoutube() && this.isSeeen() && (
          <SeeenLazy seeenId={this.props.vendorsId} isMoment={this.props.src.includes('/moment/')} />
        )}
        {this.props.jwPlatformID && (
          <RatioBox ratio={ "9/16" }>
            <div id={this.divId} ref={this._div} />
          </RatioBox>
        )}
      </VideoWrapper>
    );
  }
}

MediaPlayer.propTypes = propTypes;

export default MediaPlayer;
