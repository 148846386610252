import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import * as Selectors from "../redux/selectors";

const mapStateToProps = (state, { companyId, ...props }) => {
  const issues =
    props.issues || Selectors.selectIssuesByCompanyId(state, companyId);
  const listing =
    props.listing ||
    Selectors.selectDefaultListing(
      Selectors.selectListingsByCompanyId(state, companyId)
    );

  return {
    companyId,
    issues,
    listing
  };
};

const mapDispatchToProps = {
  fetchListings: issueIds =>
    Actions.advCompanies.listings.getByIssueIds(issueIds),
  fetchIssues: companyId =>
    Actions.advCompanies.issues.getByCompanyId(companyId)
};

const withCompanyIds = Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class WithListingIds extends React.Component {
      static propTypes = {
        companyId: PropTypes.string
      };

      componentDidMount() {
        !this.props.issues && this.props.fetchIssues(this.props.companyId);
        this.props.issue &&
          !this.props.listing &&
          this.props.fetchListings(this.props.issues.map(i => i.id));
      }

      componentDidUpdate(prevProps) {
        //load
        this.props.companyId !== prevProps.companyId &&
          !this.props.issues &&
          this.props.fetchIssuess(this.props.companyId);

        this.props.issues &&
          !this.props.listing &&
          this.props.fetchListings(this.props.issues.map(i => i.id));
      }

      render() {
        return this.props.listing ? (
          <Component listingId={this.props.listing.id} {...this.props} />
        ) : null;
      }
    }
  );

export default withCompanyIds;
