import React from "react";
import ReactDOM from "react-dom";

class Overlay extends React.Component {
  constructor(props) {
    super(props);
    if (typeof window !== "undefined" && "document" in window) {
      let portal = document.getElementById("overlayPortal");
      if (!portal) {
        let div = document.createElement("div");
        div.setAttribute("id", "overlayPortal");
        document.body.appendChild(div);
      } else if (portal.parentNode !== document.body) {
        document.body.appendChild(portal.parentNode.removeChild(portal));
      }
      this.overlayElement = document.getElementById("overlayPortal");
      this.el = document.createElement("div");
    }
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.el && this.overlayElement.appendChild(this.el);
  }

  componentWillUnmount() {
    this.el && this.overlayElement.removeChild(this.el);
  }

  render() {
    return this.el ? (
      ReactDOM.createPortal(this.props.children, this.el)
    ) : (
      <div id="contentOverlay">{this.props.children}</div>
    );
  }
}

export default Overlay;
