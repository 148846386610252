import React, { useRef, useEffect } from "react";
import useIntersectionObserver from "../hooks/useIntersectionObserver";

const InViewport = ({ Fallback = () => null, children }) => {
  const node = useRef(null);
  const [ observe, isIntersecting ] = useIntersectionObserver();
  useEffect(() => {
    if (typeof window !== "undefined") {
      observe(node.current);
    }
  }, [observe, node]);


    return (
      <div ref={node}>
        {isIntersecting ? children : <Fallback />}
      </div>
    );
  };

export default InViewport;
