import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/ 
  v2.0 | 20110126
  License: none (public domain)
  */
 html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
  // margin: 0;
  // padding: 0;
  // border: 0;
  // font-size: 100%;
  // font: inherit;
  // vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
  display: block;
  }
  body {
  line-height: 1;
  margin: 0;
  padding: 0;
  background-color: #e5e2e1;
  }
  ol, ul {
  // list-style: none;
  }
  blockquote, q {
  quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
  content: '';
  content: none;
  }
  table {
  border-collapse: collapse;
  border-spacing: 0;
  }
  * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
  html {
    font-size: 18px;
  }
  body {
    overflow-y: scroll;
  }
/* PHP pages with react header and watchlist */
.legacy-body #main-content {
    padding-right:30px;
}
.legacy-body #watchlist_body {
    display:none;
}

/* RNS */
.rns-overrides :not(.rns-keep-pre){
    font-family:inherit!important;
    text-indent:inherit;
    line-height:20px;
    font-size:inherit!important;
    margin-left:0 !important;
    margin-right:0 !important;
    max-width:100% !important;
}
.rns-safe-wrap{
    font-family:Lato,sans-serif
}
.fis-content,.fis-content .rns-keep-pre,.rns-keep-pre{
    font-family:'Roboto Mono',monospace;
    white-space:pre;
    max-width:100%
}
.rns-keep-pre{
    font-size:13px;
    overflow-x:auto;
    padding:15px;
    background:linear-gradient(to right,#fff 40%,rgba(255,255,255,0)),linear-gradient(to right,rgba(255,255,255,0),#fff 60%) 100% 0,radial-gradient(farthest-side at 0 50%,rgba(0,0,0,.2),rgba(0,0,0,0)),radial-gradient(farthest-side at 100% 50%,rgba(0,0,0,.2),rgba(0,0,0,0)) 100% 0;
    background-repeat:no-repeat;
    background-color:#fcfcfc;
    background-size:30px 100%,30px 100%,14px 100%,14px 100%;
    background-attachment:local,local,scroll,scroll
}
.fis-content br{
    display:block;
    margin:8px 0;
    content:close-quote
}
.fis-content{
    margin-left:10px;
    margin-right:10px;
    font-size:15px
}
.fis-content div{
    margin-bottom:25px;
    margin-top:25px
}
.fis-content .rns-keep-pre{
    font-size:12px;
    overflow-x:auto
}
.rns-overrides table td{
    width:auto!important;
    font-size:80%!important
}
.rns-overrides h1,.rns-overrides h2{
    line-height:initial!important
}

.rns-overrides {
    white-space:normal
}
#v4-timeline .vox-attach iframe,
iframe.vox-embed-video {
  width: 100%;
  max-width: 650px;
  display: block;
  height: 400px;
  padding-bottom: 20px;
  padding-top: 30px;
  margin: auto;
}

#v4-timeline .vox_image_preview {
  max-width: 100%;
  justify-content: center;
  text-align: center;
  display: block;
  padding-top : 30px;
  padding-bottom: 30px;
  max-height: 340px !important;
  margin: auto;
}
`;

export default GlobalStyle;
