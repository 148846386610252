import React from "react";
import { connect } from "react-redux";
import styled, { keyframes } from "styled-components";
import { Actions } from "@voxmarkets/vox-reducers";
import { X } from "./Icons";
import Text from "./Text";

const onStart = keyframes`
from { transform:translateY(100%)}
  to { transform:translateY(0)}
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999998;
  padding: 1rem;
  background-color: ${props =>
    props.status === "error"
      ? props.theme.color.negative
      : props.theme.color.positive};
  color: #fff;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  animation: ${onStart} 0.2s linear forwards;
`;

const Close = styled.button`
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
`;

class FeedbackBar extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }

  componentDidUpdate() {
    clearTimeout(this.timer);
    if (this.props.text) {
      this.timer = setTimeout(() => {
        this.props.removeFeedback();
      }, 6000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onClose = () => {
    this.props.removeFeedback();
    clearTimeout(this.timer);
  };

  render() {
    return this.props.text ? (
      <Wrapper status={this.props.status}>
        <Text white>{this.props.text}</Text>
        <Close onClick={this.onClose}>
          <X color="white" />
        </Close>
      </Wrapper>
    ) : null;
  }
}

const mapStateToProps = state => {
  return {
    text: state.feedback.text,
    status: state.feedback.status
  };
};

const mapDispatchToProps = {
  removeFeedback: () => Actions.feedback.removeFeedback()
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackBar);
