import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Dismissible from "./Dismissible2";
import { getScrollParent } from "./../utils";

const Wrapper = styled.div`
  display: ${props => (props.wrapInBlock ? "block" : "inline-block")};
`;

const Content = styled.div`
  position: absolute;
  z-index: 9;
  top: ${props => props.offsetTop}px;
  ${props => props.onBottom && "transform: translateY(-100%)"};
  ${props =>
    props.onRight
      ? css`
          right: calc(100% - 30px - ${props => props.offsetLeft}px);
        `
      : css`
          left: calc(-5px + ${props => props.offsetLeft}px);
        `};
`;

const Bubble = styled.div`
  ${props => (props.onBottom ? "margin-bottom: 12px;" : "margin-top: 12px;")};
  position: relative;
  background-color: ${props =>
    props.light ? "#e0e7ec" : props.theme.color.dark};
  border: ${props =>
    props.light ? "3px solid " + props.theme.color.dark : "3px solid #fff"};
  border-radius: 4px;
  color: ${props => (props.light ? props.theme.color.dark : "#fff")};

  white-space: nowrap;
  box-shadow: 0 0 16px
    ${props => (props.light ? "rgba(0, 0, 0, 0.25)" : "rgba(0, 0, 0, 0.75)")};
  &::before {
    content: "";
    display: block;
    position: absolute;
    ${props => (props.onBottom ? "bottom" : "top")} : -16px;
    ${props => (props.onRight ? "right" : "left")} : 4px;
    width: 0;
    height: 0;
    border-style: solid;

    ${props =>
      props.onBottom
        ? css`
            border-width: 16px 10px 0 10px;
            border-color: ${props =>
                props.light ? props.theme.color.dark : "#fff"}
              transparent transparent;
          `
        : css`
            border-width: 0 10px 16px 10px;
            border-color: transparent transparent
              ${props => (props.light ? props.theme.color.dark : "#fff")};
          `};
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    ${props => (props.onBottom ? "bottom" : "top")} :  -12px;
    ${props => (props.onRight ? "right" : "left")} : 8px;
    width: 0;
    height: 0;
    border-style: solid;

    ${props =>
      props.onBottom
        ? css`
            border-width: 12px 6px 0 6px;
            border-color: ${props =>
                props.light ? "#e0e7ec" : props.theme.color.dark}
              transparent transparent;
          `
        : css`
            border-width: 0 6px 12px 6px;
            border-color: transparent transparent
              ${props => (props.light ? "#e0e7ec" : props.theme.color.dark)};
          `};
  }
`;

class Popover extends Component {
  static propTypes = {
    atClick: PropTypes.bool,
    atHover: PropTypes.bool,
    label: PropTypes.node,
    light: PropTypes.bool,
    wrapInBlock: PropTypes.bool,
    disabled: PropTypes.bool,
    dismissibleContent: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.touch = false;
    this.state = {
      visible: false,
      alignRight: false,
      alignBottom: true,
      offsetLeft: 0,
      offsetTop: 0
    };
  }

  componentDidMount() {
    this.setState({
      alignRight: this.isOnTheRightSideOfTheWindow()
    });

    // if table offsetTop is wrong
    // https://stackoverflow.com/a/20503375
    const td = this.wrapper.current.closest("td");
    if (td) this.wrapper.current.parentElement.style.position = "relative";
  }

  isOnTheRightSideOfTheWindow = () => {
    if (this.wrapper.current) {
      const rect = this.wrapper.current.getBoundingClientRect();
      return rect ? rect.left > window.innerWidth / 2 : false;
    }
    return false;
  };

  isOnTheBottomOfTheWindow = () => {
    if (this.wrapper.current) {
      const rect = this.wrapper.current.getBoundingClientRect();
      return rect ? rect.left > window.innerWidth / 2 : false;
    }
    return false;
  };

  showPopover = () => {
    if (this.wrapper.current) {
      const rect = this.wrapper.current.getBoundingClientRect();
      const scrollElement = getScrollParent(this.wrapper.current);
      const alignBottom = rect ? rect.top > window.innerHeight / 2 : false;
      this.setState({
        visible: !this.state.visible,
        alignRight: rect ? rect.left > window.innerWidth / 2 : false,
        alignBottom,
        offsetLeft: this.wrapper.current.offsetLeft - scrollElement.scrollLeft,
        offsetTop:
          this.wrapper.current.offsetTop +
          (alignBottom ? 0 : this.wrapper.current.offsetHeight) -
          scrollElement.scrollTop
      });
    }
  };

  handleClick = e => {
    this.props.atClick && this.showPopover();
  };

  handleClikOutside = () => {
    if(this.state.visible && this.props.atClick) {
      this.showPopover()
    }
  }

  handleContentClick = e => {
    this.props.dismissibleContent && this.setState({ visible: false });
  };

  handleMouseEnter = e => {
    !this.touch && this.props.atHover && this.showPopover();
    this.touch = false;
  };

  handleMouseLeave = e => {
    !this.touch && this.props.atHover && this.setState({ visible: false });
    this.touch = false;
  };

  handleTouchStart = e => {
    this.touch = true;
  };

  render() {
    return (
      <Wrapper
        ref={this.wrapper}
        wrapInBlock={this.props.wrapInBlock}
        onTouchStart={this.handleTouchStart}
        onTouchCancel={this.handleTouchEnd}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Dismissible onClose={this.handleClikOutside}>
        <div onClick={this.handleClick}>{this.props.label}</div>
        {!this.props.disabled &&
          this.state.visible && (
            <Content
              onClick={this.handleContentClick}
              onRight={this.state.alignRight}
              onBottom={this.state.alignBottom}
              offsetLeft={this.state.offsetLeft}
              offsetTop={this.state.offsetTop}
            >
                <Bubble
                  onRight={this.state.alignRight}
                  onBottom={this.state.alignBottom}
                  light={this.props.light}
                >
                  {this.props.children}
                </Bubble>
            </Content>
          )}
          </Dismissible>
      </Wrapper>
    );
  }
}

export default Popover;
