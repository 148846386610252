import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

export default function PageMeta({
  title = "Vox Markets - Market Investor News, Insights & Analysis",
  type = "article",
  image,
  description,
  keywords,
  url,
  linkedData
}) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:type" content={type} />
      {url && <link rel="canonical" href={url} />}
      {image && <meta property="og:image" content={image} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      <meta property="og:site_name" content="Vox Markets" />
      <meta name="twitter:card" content={(image && (image.includes('/1920/cms/') || image.includes(`public-media/videos`))) ? `summary_large_image` : `summary` } />
      <meta name="twitter:site" content="@voxmarkets" />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
      {title && <meta name="twitter:image:alt" content={title} />}
      {url && <meta property="og:url" content={url} />}
      {linkedData && <script type="application/ld+json">{JSON.stringify(linkedData)}</script>}
    </Helmet>
  );
}

PageMeta.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  linkedData: PropTypes.object,
};


