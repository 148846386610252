import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import useOptimizedImage from "../hooks/useOptimizedImage";

const Picture = ({ src, size = 'PREVIEW', className, ...props }) => {
  const node = useRef(null);
  const [observe, isIntersecting] = useIntersectionObserver();

  const { jpeg, png, webp, src: def } = useOptimizedImage(src, size);

  useEffect(() => {
    if (typeof window !== "undefined") {
      observe(node.current);
    }
  }, [observe, node]);

  return (
    <picture className={className} ref={node}>
      {isIntersecting && (
        <>
        {webp && <source type="image/webp" srcSet={webp} />}
        {png && <source type="image/png" srcSet={png} />}
        {jpeg && <source type="image/jpeg" srcSet={jpeg} />}
        {def && <img src={def} alt={props.alt || ""} {...props} />}
        </>
      )}
    </picture>
  );
};

export default styled(Picture)`
display:block;
img {
max-width: 100%;
}
`;
