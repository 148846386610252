import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { Text } from "./Typography";
import Modal from "./Modal2";
import { Button, Link } from "./Utils";
import { Input, Label, Field } from "./Forms";
import { Grid, Cell, Spacing } from "./Layout";
import { Loader } from "./Utils";
import { Actions } from "@voxmarkets/vox-reducers";

const ButtonLogin = styled(Button)`
  && {
    font-size: 1.2rem;
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.color.main : theme.color.positive};
    border-color: ${({ theme }) => theme.color.positive};
    padding: 0.5em 3em;
    max-width: 100%;
  }
`;

const Footer = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

const Message = styled(props => (
  <div className={props.className}>
    <Text center>{props.children}</Text>
  </div>
))`
  background-color: ${({ theme }) => theme.color.light};
  padding: 8px;
  margin: 8px auto;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.dark};
`;

export class LoginButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginForm: false
    };
    console.log(this.props);
  }

  showLoginForm = e => {
    this.setState({
      loginForm: true
    });
  };

  render() {
    if (this.props.userData.login) {
      return null;
    }
    return (
      <span>
        <Button onClick={this.showLoginForm}>{this.props.children}</Button>
        {this.state.loginForm ? (
          <LoginForm
            onClose={e =>
              this.setState({
                loginForm: false
              })
            }
          />
        ) : null}
      </span>
    );
  }
}

export const LoginButton = connect((state, props) => ({
  userData: state.userData
}))(LoginButtonComponent);

export class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: "",
      remember: true,
      progress: this.props.authentication && this.props.authentication.loggingIn
    };
  }

  defaultCallback = () => {
    typeof window !== "undefined" && window.location.reload();
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.authentication.loggedIn !==
        this.props.authentication.loggedIn &&
      nextProps.authentication.loggedIn === true
    ) {
      this.props.onClose && this.props.onClose();
      this.props.callback ? this.props.callback() : this.defaultCallback();
    }
  };

  submit = e => {
    e.preventDefault();
    this.props.authenticate(
      this.state.login,
      this.state.password,
      this.state.remember
    );
  };

  render() {
    return (
      <Modal onClose={this.props.onClose} className={this.props.className}>
        <Spacing top={2}>
          <Grid>
            <Cell size={8}>
              <Text size={2}>Login</Text>
            </Cell>
            <Cell size={4} style={{ textAlign: "right" }}>
              <Button as="a" href="/registration">Register</Button>
            </Cell>
          </Grid>
        </Spacing>
        {this.props.authentication &&
          typeof this.props.authentication.error == "string" &&
          this.props.authentication.error.indexOf("password") > -1 && (
            <Message>{this.props.authentication.error}</Message>
          )}
        {this.props.authentication.loggingIn ? (
          <Spacing vertical={4}>
            <Loader center />
          </Spacing>
        ) : (
          <form onSubmit={this.submit}>
            <Field top={1}>
              <Label bold>Username or Email Address:</Label>
              <Input
                type="text"
                name="login"
                value={this.state.login}
                onInput={e =>
                  this.setState({ login: e.target.value, message: "" })
                }
              />
            </Field>
            <Field top={1}>
              <Label bold>Password:</Label>
              <Input
                type="password"
                name="password"
                value={this.state.password}
                onInput={e =>
                  this.setState({ password: e.target.value, message: "" })
                }
              />
            </Field>
            <Spacing top={1}>
              <Field>
                <Label>
                  <input
                    id="remembertick"
                    type="checkbox"
                    checked={this.state.remember}
                    onChange={e =>
                      this.setState({ remember: e.target.checked })
                    }
                  />
                  Remember me
                </Label>
              </Field>
            </Spacing>
            <Spacing top={1}>
              <Footer>
                <Cell fit>
                  <Link href="https://www.voxmarkets.co.uk/wp-login.php?action=lostpassword">
                    Lost your password?
                  </Link>
                </Cell>
                <Cell fit>
                  <ButtonLogin
                    disabled={
                      this.state.login === "" || this.state.password === ""
                    }
                  >
                    Login
                  </ButtonLogin>
                </Cell>
              </Footer>
            </Spacing>
            <Spacing top={1} />
          </form>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    authentication: state.authentication ? state.authentication : {},
    userData: state.userData ? state.userData : {}
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  authenticate: (username, password, rememberMe) =>
    dispatch(
      Actions.authentication.loginWithWPCredentials(
        username,
        password,
        rememberMe
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
