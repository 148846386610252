import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
`;

export const Ratio = styled.div`
  display: block;
  padding-bottom: ${props =>
    props.ratio ? `calc(${props.ratio} * 100%)` : `100%`};
`;

const Content = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
`;

const RatioBox = props => (
  <Wrapper>
    <Ratio ratio={props.ratio} />
    <Content>{props.children}</Content>
  </Wrapper>
);

export default RatioBox;
