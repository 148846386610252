import React, { useContext } from "react";
import styled, { css } from "styled-components";
import loadable from "@loadable/component";
import { useSelector } from "react-redux";

import Header from "./Header";
import { Border, Spacing } from "./Layout";
import WatchlistLink from "./Watchlist/WatchlistLink";
import WatchlistUpdater from "../containers/WatchlistUpdater";
import * as Select from "./../redux/selectors";
import Breakpoint from "./Breakpoint";

import ThemeContext from "./ThemeContext";

import { useAds } from "features/adsense/queries";
import { AdComponent } from "features/adsense/components";

const WatchlistToggle = loadable(() => import("./Watchlist/ToggleWrapper"));

const Footer = loadable(() => import("./Footer"));

const HeaderContainer = styled.div`
  top: 0;
  left: 0;
  right: ${(props) => props.rightMargin}px;
  position: fixed;
  z-index: 1001;
`;

const WatchlistContainer = styled.div`
  position: fixed;
  right: ${(props) => props.rightMargin}px;
  top: 70px;
  bottom: 0;
  z-index: 9;
  width: 420px;
  transform: translateX(${(props) => (props.collapsed ? 340 : 0)}px);
  transition: transform 400ms ease-in-out;
  @media (min-width: 1025px) {
  top: 110px;
  }
`;

const PageContainer = styled.div`
padding-top: calc(100px + 1rem);
  @media (min-width: 1025px) {
  padding-top: calc( 140px + 1rem);
  }
  padding-right: ${(props) => props.rightMargin}px;
`;




export default function PageLayout({ watchlist, footer, compactFooter, hideSubnav, banner = true, theme = 'default', children }) {
  const rightMargin = useSelector((state) => state.ui.appRightMargin);
  const collapsed = useSelector((state) => state.ui.watchlist.collapsed);
  const loggedIn = useSelector(Select.loggedIn);

  const { data: ads, loading } = useAds();

  const bannerAd = banner && ads && ads.find( unit => unit.slot === 'page-header' );

  const isWatchlist = watchlist && loggedIn ? true : false;
  
  return (
    <ThemeContext.Provider value={theme}>
      <HeaderContainer rightMargin={rightMargin}>
        <Header isWatchlist={isWatchlist} theme={theme} />
      </HeaderContainer>
    <PageContainer rightMargin={rightMargin}>
      { bannerAd && <Spacing bottom={2}><AdComponent slot="page-header" /></Spacing> }
      <WatchlistMargin isWatchlist={isWatchlist}>
        <Border>{children}</Border>
      </WatchlistMargin>
      { (footer||compactFooter) && <Footer isWatchlist={isWatchlist} compact={compactFooter} loggedIn={loggedIn} themeContext={theme} /> }
    </PageContainer>
    <div>
      {isWatchlist && (
        <Breakpoint min={576}>
          <WatchlistContainer rightMargin={rightMargin} collapsed={collapsed} hideSubnav={hideSubnav}>
            <WatchlistUpdater />
            <WatchlistToggle header={true} />
          </WatchlistContainer>
        </Breakpoint>
      )}

      <WatchlistLink />
    </div>
    </ThemeContext.Provider>
  );
}

//-----------------------
//-----------------------
//-----------------------
const WatchlistMarginWrapper = styled.div`
  ${(props) =>
    props.isWatchlist &&
    css`
      @media (min-width: 576px) {
        padding-right: 80px;
      }
      @media (min-width: 1730px) {
        padding-right: ${(props) => (props.collapsed ? 80 : 420)}px;
      }
    `} height: 100%;
  width: 100%;
`;

export function WatchlistMargin({ children, isWatchlist }) {
  const collapsed = useSelector((state) => state.ui.watchlist.collapsed);

  return (
    <WatchlistMarginWrapper collapsed={collapsed} isWatchlist={isWatchlist}>
      {children}
    </WatchlistMarginWrapper>
  );
}
