import React from "react";
import ReactDOM from "react-dom";
import Dismissible from "./Dismissible";
import { Delete } from "./Icons";
import styled, { keyframes } from "styled-components";
import Spinner from "./Spinner";
import Link from "./Link";
import ButtonComponent from "./Button";

export { Link };

// export const TextInput = styled.input`
//   && {
//     border: 1px solid #aabecb;
//     border-radius: 4px;
//     padding: 2px 11px;
//     font-size: 18px;
//     font-family: "Roboto Condensed", sans-serif;

//     white-space: nowrap;
//     vertical-align: middle;

//     width: 100%;

//     line-height: 1.25;
//     &::placeholder {
//       color: #6b8da5;
//       font-family: "Roboto Condensed", sans-serif;
//       font-weight: 400;
//     }
//   }
// `;

export const Panel = styled.div`
  && {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
  }
`;

export const Loader = Spinner;

export class CenteredLoader extends React.Component {
  render() {
    return (
      <div
        key="loader"
        id={`infiniteLoad-${this.props.id}`}
        style={{ textAlign: "center" }}
      >
        {!(this.props.loading === true) ? (
          <ButtonComponent
            href={this.props.href}
            primary
            onClick={e => {
              e.preventDefault();
              this.props.getData();
            }}
          >
            Load more
          </ButtonComponent>
        ) : (
          <Spinner size="50" />
        )}
      </div>
    );
  }
}

const dotsKeyframes = keyframes`
  0% { content: "."; }
  20% { content: ".."; }
  40% { content: "..."; }
  60% { content: "...."; }
  80% { content: "....."; }
  100% { content: "......"; }
`;

export const Loader2 = styled.div`
  && {
    &::after {
      content: "";
      animation: ${dotsKeyframes} 3s linear infinite;
    }
  }
`;

export const FadeOut = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 15px 0;

  /* "transparent" only works here because == rgba(0,0,0,0) */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.75) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.75) 100%
  );
`;

export const Button = styled.button`
  && {
    outline: none;
    color: #fff;
    background-color: ${props => props.color || "#135d80"};

    border: 1px solid ${props => props.color || "#135d80"};
    border-radius: 4px !important;
    padding: ${props => (props.icon ? "0" : "3px 11px")};
    font-family: "Roboto Condensed", sans-serif !important;
    font-weight: ${props => (props.bold ? 700 : 400)};

    display: inline-flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    line-height: 1.25;

    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
    }

    &:hover {
      background-color: #166d96;
      border-color: #1a7dac;
      cursor: pointer;
    }
  }
`;

export const DeleteButton = styled(props => (
  <button {...props}>
    <Delete width={20} height={20} />
  </button>
))`
  background: ${props => props.theme.color.negative};
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin-right: 4px;
  cursor: pointer;
  border: none;
  padding: 0;
`;

const imageLoadError = (img, props) => {
  if (props.placeholder && img.src !== props.placeholder) {
    img.src = props.placeholder;
  } else {
    img.style.opacity = "0";
  }
};
const imageLoad = (img, props) => {
  img.style.opacity = "1";
};

export const Img = styled(
  ({ src, baseUrl, width, height, style, className, ...props }) => {
    if (!/^(https?:)|(\/\/)/.test(src)) {
      src = (props.baseUrl || "https://www.voxmarkets.co.uk") + src;
    }
    return (
      <img
        className={className}
        src={src}
        width={width}
        height={height}
        style={style}
        onError={e => imageLoadError(e.target, props)}
        onLoad={e => imageLoad(e.target, props)}
        alt={props.alt||""}
      />
    );
  }
)``;

const DropdownList = styled(
  Dismissible(({ listRef, alignRight, visible, ...props }) => (
    <div {...props} ref={listRef} />
  ))
)`
  font-family: ${props => props.theme.font.default};
  background-color: ${props => (props.light ? "#fff" : props.theme.color.dark)};
  border: ${props =>
    props.light ? "1px solid " + props.theme.color.main : "3px solid #fff"};
  border-radius: 4px;
  color: ${props => (props.light ? props.theme.color.dark : "#fff")};
  padding: 6px 0;
  position: absolute;
  z-index: 3;
  top: 40px;
  ${props => (props.alignRight ? "right" : "left")} : -5px;
  white-space: nowrap;
  box-shadow: 0 0 16px
    ${props => (props.light ? "rgba(0, 0, 0, 0.25)" : "rgba(0, 0, 0, 0.75)")};
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -16px;
    ${props => (props.alignRight ? "right" : "left")} : 4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 14px 10px;
    border-color: transparent transparent
      ${props => (props.light ? props.theme.color.main : "#fff")};
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -12px;
    ${props => (props.alignRight ? "right" : "left")} : 8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 12px 6px;
    border-color: transparent transparent
      ${props => (props.light ? "#fff" : props.theme.color.dark)};
  }
  & > * {
    padding: 3px 8px;
    cursor: pointer;
  }
  a:hover {
    background-color: ${props => props.theme.color.main};
  }
`;

export const Dropdown = class extends React.Component {
  state = {
    visible: this.props.visible || false,
    alignRight: this.props.alignRight || false
  };

  close = e => {
    setTimeout(() => {
      this.setState({ visible: false });
    }, 100);
  };

  toggle = e => {
    this.setState(
      prevState => ({ visible: !prevState.visible }),
      this.getOffset
    );
  };

  getOffset = () => {
    if (this.listElement && this.state.visible) {
      const node = ReactDOM.findDOMNode(this);
      const nodeBox = this.listElement.getBoundingClientRect();
      const parentBox = node.offsetParent.getBoundingClientRect();
      this.setState({
        alignRight: nodeBox.right > parentBox.right
      });
    } else {
      this.setState({
        alignRight: this.props.alignRight || false
      });
    }
  };

  render() {
    return (
      <div style={{ position: "relative", display: "inline-block" }}>
        <span onClick={this.toggle} style={{ cursor: "pointer" }}>
          {this.props.label}
        </span>
        <DropdownList
          visible={this.state.visible}
          onClose={this.close}
          alignRight={this.state.alignRight}
          listRef={list => (this.listElement = list)}
          light={this.props.light}
        >
          {this.props.children}
        </DropdownList>
      </div>
    );
  }
};

//deprecated
export const BoxShadow = styled.div`
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.color.shadow};
  background-color: #fff;
  margin-bottom: 10px;
  ${props => (props.rounded ? "border-radius: 3px;" : null)};
  ${props => (props.inside ? "overflow: hidden;" : null)};
  ${props => props.noMarginCollaps && "display: inline-block; width: 100%;"};
`;

export const BoxShadow2 = styled.div`
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.color.shadow};
  background-color: #fff;
  border-radius: 3px;
  display: inline-block;
  width: 100%;
  ${props => props.spacing && `margin-top: ${props.spacing}rem`};
  ${props => props.inside && "overflow: hidden"};
`;

export const ResetCss = styled.div``;

export const highlightOnPropUpdate = (
  Component,
  compareProps,
  shouldComponentUpdate
) => {
  return class extends React.Component {
    state = {
      up: false,
      down: false,
      fade: false
    };
    shouldComponentUpdate = shouldComponentUpdate.bind(this);
    componentWillReceiveProps = compareProps.bind(this);
    render() {
      const Styled = styled(Component)`
        @keyframes fade {
          from {
            color: ${({ theme }) =>
              this.state.up
                ? theme.color.positive
                : this.state.down
                  ? theme.color.negative
                  : ""};
          }
          to {
            color: #333;
          }
        }
        animation: ${() => this.state.fade && "fade 3s"};
      `;
      return <Styled {...this.props} />;
    }
  };
};

const GenericLinkOptions = styled.div``;
const GenericLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${props => (props.vertical ? "stretch" : "center")};
  flex-direction: ${props => (props.vertical ? "column" : "row")};
  font-weight: 700;
  font-size: 0.8rem;
  padding-top: 4px;
  padding-bottom: 4px;

  ${GenericLinkOptions} {
    margin-top: ${props => (props.vertical ? "0.3rem" : "0")};
  }
`;

const GenericLinkData = styled(Link)`
  display: flex;
  align-items: center;
`;

export const GenericLink = ({
  vertical,
  href,
  Logo,
  Name,
  FollowToggle,
  ...props
}) => {
  return (
    <GenericLinkWrapper vertical={vertical}>
      <GenericLinkData href={href}>
        {Logo}
        {Name}
      </GenericLinkData>
      <GenericLinkOptions>{FollowToggle}</GenericLinkOptions>
    </GenericLinkWrapper>
  );
};
