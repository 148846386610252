import styled, { css } from "styled-components";

const UL = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${props => props.horizontal && "display: flex"};
  li {
    margin: 0;
    padding: 0;
    ${props => props.block && "overflow: auto"};
  }

  ${props =>
    props.spacing &&
    !props.horizontal &&
    css`
      > li + li {
        margin-top: ${props.spacing}rem;
      }
    `};

  ${props =>
    props.spacing &&
    props.horizontal &&
    css`
      > li + li {
        margin-left: ${props.spacing}rem;
      }
    `};

  ${props =>
    props.zebra &&
    css`
      > li {
        background-color: #fff;
      }
      > li:nth-child(${props => (props.odd ? "odd" : "even")}) {
        background-color: ${props => props.theme.color.light};
      }
    `};
`;

export default UL;
