import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import { Actions } from "@voxmarkets/vox-reducers";
import Image from "components/Image";
import Text from "./Text";

const StyledLink = styled(({ top, ...props }) => <Link {...props} />)`
  text-decoration: none;
  display: block;
`;

const Author = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Avatar = styled(Image)`
  border-radius: 50%;
  margin-right: 8px;
  background-color: #aabecb;
  width: 40px;
  height: 40px;
  display: block;
`;

const NameCat = styled.div`
  display: ${props => props.row? 'flex' : 'block' };
  ${StyledLink} {
  padding-right: 8px;
  }
`

const getCategoryLink = ({articleContext, categorySlug, collectionIDs = []}) => {
  if(articleContext && articleContext === "GCI" || collectionIDs.includes('663b34c4c3a6e4e9682027c8')){
    return `/gci/${encodeURIComponent(categorySlug)}/`;
  }
  else {
    return `/all-articles/${encodeURIComponent(categorySlug)}/`;
  }
}

const ArticleAuthor = ({
  name,
  avatar,
  login,
  cmsAuthor,
  category,
  categorySlug,
  articleContext,
  hideAvatar = false,
  dark = false,
  collectionIDs
}) => {
  if(cmsAuthor && cmsAuthor.hasOwnProperty('slug')){
    return (<Author>
      {!hideAvatar && (
        <Link to={`/authors/${encodeURIComponent(cmsAuthor.slug)}/`}>
          <Avatar
            width="40px"
            height="40px"
            size="AVATAR"
            alt={name}
            src={
            avatar ||
            "https://www.voxmarkets.co.uk/wp-content/themes/voxmarkets3/images/avatar_default.png"
            }
          />
        </Link>
      )}
      <NameCat row={hideAvatar}>
        <StyledLink to={`/authors/${encodeURIComponent(cmsAuthor.slug)}/`} top>
          <Text size={0.84}  white={dark}>{cmsAuthor.name}</Text>
        </StyledLink>
        { categorySlug ? (
            <StyledLink to={getCategoryLink({ articleContext, categorySlug, collectionIDs })} top>
              <Text size={0.84} bold  white={dark}>{category}</Text>
            </StyledLink>
        ) : <Text size={0.84}  white={dark}>{category}</Text>}
      </NameCat>
    </Author>)
  }
  return (<Author>
    {!hideAvatar && (
      <Avatar
        width="40px"
        height="40px"
        size="AVATAR"
        alt={name}
        src={
        avatar ||
        "https://www.voxmarkets.co.uk/wp-content/themes/voxmarkets3/images/avatar_default.png"
        }
      />
    )}
    <NameCat row={hideAvatar}>

    <Text as="span" size={0.84} white={dark}>
    
      {cmsAuthor? cmsAuthor.name : name}
      </Text>
      { categorySlug ? (
        <StyledLink to={getCategoryLink({ articleContext, categorySlug, collectionIDs })} top>
          <Text size={0.84} bold  white={dark}>{category}</Text>
        </StyledLink>
      ) : <Text size={0.84} bold  white={dark}>{category}</Text>}
      </NameCat>
      </Author>)
    };

ArticleAuthor.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  login: PropTypes.string,
  category: PropTypes.string
};

class ArticleAuthorContainer extends React.Component {
  constructor(props) {
    super(props);
    if (!props.user) props.fetchUserData(props.userId);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.userId !== prevProps.userId) {
      if (!this.props.user) this.props.fetchUserData(this.props.userId);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.user, nextProps.user);
  }

  render() {
    return (
      <div>
        {this.props.user && (
          <ArticleAuthor
            cmsAuthor={this.props.cmsAuthor}
            name={this.props.user.name}
            avatar={this.props.user.avatarURI}
            login={this.props.user.login}
            category={this.props.category}
            categorySlug={this.props.categorySlug}
            articleContext={this.props.articleContext}
            hideAvatar={this.props.hideAvatar}
            dark={this.props.dark}
            collectionIDs={this.props.collectionIDs}
          />
        )}
      </div>
    );
  }
}

ArticleAuthorContainer.propTypes = {
  userId: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    user: state.users[props.userId]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUserData: userId => {
      dispatch(
        Actions.users.fetchUserData(isNaN(userId) ? userId : parseInt(userId))
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleAuthorContainer);
