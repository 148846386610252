import React from "react";
import styled from "styled-components";
import { Spacing } from "./Layout";
import { DeleteButton } from "./Utils";
import { Text } from "./Typography";
import { UpDown, Check, Attach } from "./Icons";

const Wrapper = styled.div`
  position: relative;
`;

const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
  width: 20px;
  display: flex;
  justify-content: center;
  ${props => props.background && `background-color:${props.background}`};
`;

export const Input = styled.input`
  font-family: ${props => props.theme.font.default};
  border: 1px solid ${props => props.theme.color.border};
  font-weight: 500;
  width: 100%;
  border-radius: 2px;
  padding: 0.446rem;
  ${props => props.small && "height:30px; padding: 0.218rem"};
  ${props => props.mini && "height:27px; padding: 0 0.2rem"};
  color: ${props => props.theme.color.text};
  font-size: 0.78rem;
  &::-ms-clear {
    display: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const Textarea = styled.textarea`
  font-family: ${props => props.theme.font.default};
  font-weight: 500;
  border: 1px solid ${props => props.theme.color.border};
  width: 100%;
  border-radius: 2px;
  padding: 0.446rem;
  color: ${props => props.theme.color.text};
  font-size: 0.78rem;
  resize: vertical;
  ${props => props.height && `height:${props.height};`};
`;

export const Radio = styled.input.attrs({
  type: "radio"
})``;

const RadioBoxWrapper = styled.label`
  display: inline-block;
  position: relative;
  margin-right: 1.5rem;
  padding: 2px 0 2px 28px;
  cursor: pointer;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  span:last-child {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.color.border};
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #6b8ea4;
    }
  }

  input:checked ~ span:last-child {
    &:after {
      display: block;
    }
  }
`;

export const RadioBox = props => {
  const { children, ...other } = props;
  return (
    <RadioBoxWrapper>
      <Text as="span">{children}</Text>
      <Radio {...other} />
      <span />
    </RadioBoxWrapper>
  );
};

export const CheckBox = props => {
  const { children, ...other } = props;
  return (
    <RadioBoxWrapper>
      <Text as="span" size={0.89}>{children}</Text>
      <input type="checkbox" {...other} />
      <span />
    </RadioBoxWrapper>
  );
};

const ToggleWrapper = styled.label`
  border-radius: 50%;
  position: relative;
  display: block;
  font-size: 26px;
  height: calc(1em + 4px);
  width: calc(1.75em + 4px);
  input {
    position: absolute;
    opacity: 0;
  }
  span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #d30713;
    border-radius: calc(0.5em + 2px);
    border: 1px solid #d30713;
    &::after {
      content: "";
      display: block;
      width: 1em;
      height: 1em;
      border-radius: 100%;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid #d30713;
    }
  }
  input:checked + span {
    background: #008540;
    border-color: #008540;
    &::after {
      position: absolute;
      right: 0;
      left: auto;
      border-color: #008540;
    }
  }
`;

export const Toggle = ({ className, children, ...props }) => (
  <ToggleWrapper>
    <input type="checkbox" {...props} />
    <span />
  </ToggleWrapper>
);

export const InputIcon = props => (
  <Wrapper>
    <Input {...props} />
    {props.icon && <Icon>{props.icon}</Icon>}
    {props.valid && (
      <Icon>
        <Check />
      </Icon>
    )}
  </Wrapper>
);

const buttonFontSize = ({ big, small, mini }) => {
  if (big) return `font-size: 0.888rem;`;
  if (small) return `font-size: 0.888rem;`;
  if (mini)
    return `font-size: 0.78rem; font-weight: 500;padding-left: 1rem; padding-right:1rem;`;
  return `font-size: 1rem;`;
};

const buttonColors = props => {
  if (props.primary) return `background-color: #3a9535; color: #fff;`;
  if (props.secondary) return `background-color: #36a1da; color: #fff;`;
  if (props.vox) return `background-color: #135d80; color: #fff;`;
  if (props.info)
    return `background-color: #ccc; color: ${props => props.theme.color.text};`;
  return `background-color: transparent; color: ${props =>
    props.theme.color.text};`;
};

export const Button = styled.button`
  font-family: ${props => props.theme.font.default};
  font-weight: 700;
  border: 0;
  padding: 0.37em 2rem;
  /* width: 50%; */
  ${props =>
    props.block && "width: 100%;padding-left: 0.2rem; padding-right:0.2rem"};
  ${props => props.compact && "padding-left: 0.5rem; padding-right:0.5rem"};
  ${props => buttonFontSize(props)};
  ${props => buttonColors(props)};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #cccccc;
    ${props => props.primary && "background-color: #32812d;"};
    ${props => props.secondary && "background-color: #1D88C1;"};
    ${props => props.vox && "background-color: #004467;"};
    ${props => props.info && "background-color: #B3B3B3;"};
  }
`;

export const Select2 = styled.select`
  font-family: ${props => props.theme.font.default};
  border: 1px solid ${props => props.theme.color.border};
  background: transparent;
  width: 100%;
  border-radius: 4px;
  padding: 0.446rem;
  color: ${props => props.theme.color.text};
  font-size: 0.78rem;
  appearance: none;
`;

const SelectDark = styled.select`
  appearance: none;
  color: #fff;
  height: 2em;
  background-color: ${props => props.inactive ? '#475561' : props.theme.color.dark};
padding: ${ props => props.large ? '8px 10px' : '4px 10px' };
  font-size: ${ props => props.large ? '1.1rem' : 'inherit' };
  border: none;
  border-radius: 4px;
  width: 100%;
`;

export const Select = ({ dark, inactive, ...props }) => (
  <Wrapper>
    {dark ? (
      <SelectDark {...props} inactive={inactive}>{props.children}</SelectDark>
    ) : (
      <Select2 {...props}>{props.children}</Select2>
    )}
    <Icon background={dark ? inactive ? "#475561" : "#0c273e" : "transparent"}>
      <UpDown
        color={dark ? "#fff" : props.theme ? props.theme.color.main : "#aabecb"}
      />
    </Icon>
  </Wrapper>
);

const InputFileWrapper = styled.label`
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
  svg {
  cursor: pointer;
  }
  input {
  opacity: 0;
  position: absolute;
  top: -100px;
  }
`;

export class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filename: ""
    };
  }
  getFileName = e => {
    let files = e.target.files;
    this.setState({
      filename: [...files].map(file => file.name)
    });
    this.props.onChange && this.props.onChange(e);
  };
  clear = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({ filename: "" });
    this.props.onClear && this.props.onClear(e);
  };
  render() {
    return (
      <InputFileWrapper>
        {this.state.filename.length === 0 && [
          <input
            ref={i => (this.input = i)}
            type="file"
            {...this.props}
            onChange={this.getFileName}
          />,
          <Attach color="#666" />
        ]}
        {this.state.filename && (
          <Text bold size="0.8" style={{ whiteSpace: "nowrap" }}>
            <DeleteButton onClick={this.clear} />
            <span style={{ display: "inline-block", verticalAlign: "middle" }}>
              {this.state.filename.map(filename => (
                <span>
                  {filename}
                  <br />
                </span>
              ))}
            </span>
          </Text>
        )}
      </InputFileWrapper>
    );
  }
}

const FieldElement = styled(Spacing)`
  display: block;
`;
export const Field = (props) => <FieldElement as="label" {...props} />;

export const Label = styled(Text)`
  padding-bottom: 5px;
`;
