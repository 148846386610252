import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Button from "./Button";
import { Grid, Cell, Spacing } from "./Layout";
import { Text } from "./Typography";
import Alert from "./Alert";
import { Actions } from "@voxmarkets/vox-reducers";
import { legacyAPI } from "@voxmarkets/api-sdk";
import { loggedIn as selectIsLoggedIn } from "./../redux/selectors";

const Overlay = styled.div`
  position: absolute;
  top:420px;
  left:8px;
  right: 8px;
  box-shadow: 0 0 40px 42px rgba(0,0,0,0.33);
`;

class ConfirmationMessage extends PureComponent {
  render() {
    return (
      <Overlay>
      <Alert>
        <Text size={1.15} center>
          To continue viewing RNS, please confirm that you are a{" "}
          <strong>Private Investor*</strong>
        </Text>
        <Spacing top={1}>
          <Grid center>
            <Cell fit spacing={0.5}>
              <Button primary onClick={this.props.confirmAction}>
                I am a Private Investor
              </Button>
            </Cell>
            <Cell fit>
              <Button secondary onClick={this.props.denyAction}>
                I am not a Private Investor
              </Button>
            </Cell>
          </Grid>
        </Spacing>

        <p>
          * A <strong>Private Investor</strong> is a recipient of the
          information who meets all of the conditions set out below, the
          recipient:
        </p>
        <ol>
          <li>Obtains access to the information in a personal capacity;</li>
          <li>
            Is not required to be regulated or supervised by a body concerned
            with the regulation or supervision of investment or financial
            services;
          </li>
          <li>
            Is not currently registered or qualified as a professional
            securities trader or investment adviser with any national or state
            exchange, regulatory authority, professional association or
            recognised professional body;
          </li>
          <li>
            Does not currently act in any capacity as an investment adviser,
            whether or not they have at some time been qualified to do so;
          </li>
          <li>
            Uses the information solely in relation to the management of their
            personal funds and not as a trader to the public or for the
            investment of corporate funds;
          </li>
          <li>
            Does not distribute, republish or otherwise provide any information
            or derived works to any third party in any manner or use or process
            information or derived works for any commercial purposes.
          </li>
        </ol>
      </Alert>
      </Overlay>
    );
  }
}
//-----------------------
//-----------------------
//-----------------------

const ContentWrapper = styled.article`
  padding: 0.5rem 0;
  ${props => props.hide && 'opacity:0.25;' }
`;

class Content_ extends PureComponent {
  _content = React.createRef();
  componentDidMount() {
    !this.props.announcement && this.props.getContents(this.props.storyId);
    this.props.announcement && this.targetBlank();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storyId !== this.props.storyId) {
      !this.props.announcement && this.props.getContents(this.props.storyId);
    }
    if (this.props.announcement !== prevProps.announcement) {
      this.targetBlank();
    }
  }

  targetBlank() {
    const node = this._content.current;
    node &&
      [...node.getElementsByTagName("a")].forEach(link => {
        link.setAttribute("target", "_blank");
      });
  }

  render() {
    return (
      <ContentWrapper hide={this.props.hidden}>
        <Text black>
          <div
            ref={this._content}
            className="rns-overrides"
            dangerouslySetInnerHTML={{
              __html: this.props.announcement
                ? this.props.announcement.content
                : null
            }}
          />
        </Text>
      </ContentWrapper>
    );
  }
}

const Content = connect(
  (state, props) => ({
    announcement: state.rns.byStoryId[props.storyId]
  }),
  {
    getContents: storyId => Actions.rns.fetchContentByStoryIds([storyId])
  }
)(Content_);

//-----------------------
//-----------------------
//-----------------------

class PrivateInvestor extends PureComponent {
  onConfirm = e => {
    legacyAPI.Users.setPrivateInvestor().then(data => {
      this.props.addInvestor();
      
    });
    this.props.addInvestor();
    window.localStorage.setItem("privateInvestor", true);
  };

  onDeny = e => {
    window.localStorage.removeItem("privateInvestor");
    window.location.replace("/");
  };

  render() {
    return (
      <div>
        {(!this.props.loggedIn && !this.props.privateInvestor) && (
          <ConfirmationMessage
            confirmAction={this.onConfirm}
            denyAction={this.onDeny}
            key="overlay"
          />
        )}
        <Content storyId={this.props.storyId} key="content"/>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    privateInvestor: state.userData.privateInvestor,
    loggedIn: selectIsLoggedIn(state, props)
  }),
  {
    addInvestor: () => Actions.userData.addUserData("privateInvestor", true)
  }
)(PrivateInvestor);
