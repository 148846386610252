import React, { PureComponent } from "react";
import styled from "styled-components";
import Popover from "./Popover";
import CompanyBadgeExtra from "./Company/CompanyBadgeExtra";

const Wrapper = styled.div`
  width: 370px;
  padding: 0.5rem;
`;

class ListingBadgePopover extends PureComponent {
  render() {
    return (
      <Popover
        label={this.props.children}
        atClick={this.props.atClick}
        atHover={this.props.atHover}
        wrapInBlock={this.props.wrapInBlock}
        light
      >
        <Wrapper>
          <CompanyBadgeExtra
            listingId={this.props.listingId}
            companyId={this.props.companyId}
          />
        </Wrapper>
      </Popover>
    );
  }
}

export default ListingBadgePopover;
