import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import Dialog from "./Dialog2";
import { connect } from "react-redux";
import UiActions from "./../redux/actions";
import { Helmet } from "react-helmet";

//chnages to modal
// - width in rem
// - support for mobile

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: block;
  outline: 0;
  overflow-x: hidden;
  overflow-y: scroll;
`;

//transition: opacity .15s linear;
const onStart = keyframes`
from {opacity: 0; transform:translateY(-100)}
  to {opacity: 1;transform:translateY(0)}
`;

const Content = styled.div`
  position: relative;
  width: ${props => (props.width ? `${props.width}rem` : "660px")};
  margin: calc(70px + 1rem) auto 5rem;
  @media (min-width: 1025px) {
    margin: calc(105px + 1rem) auto 5rem;
  }
  ${props => props.top && `margin-top: ${props.top}rem`};
  max-width: calc(100% - 1rem);
  z-index: 1;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  animation: ${onStart} 0.15s linear forwards;
  pointer-events: none;
`;

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.dialog = React.createRef();
  }

  handleDocumentClick = e => {
    console.log('Modal click', e.target);
    //e.stopPropagation();
    this.dialog.current &&
      !this.dialog.current.contains(e.target) &&
      this.props.onClose &&
      this.props.onClose();
  };

  componentWillUnmount() {
    this.props.setAppRightMargin(0);
  }

  render() {
    return (
      <Wrapper
        onClick={this.handleDocumentClick}
        onScroll={this.props.onScroll}
      >
        <Helmet>
          {/* eslint-disable-next-line react/style-prop-object */}
          <html style="overflow: hidden;position: relative;height: 100%" />
          {/* eslint-disable-next-line react/style-prop-object */}
          <body style="overflow: hidden;position: relative;height: 100%" />
        </Helmet>
        <Backdrop />
        <Content width={this.props.width} top={this.props.top}>
          <Dialog
            onClose={this.props.onClose}
            noPadding={this.props.noPadding}
            innerRef={this.dialog}
            iconColor={this.props.iconColor}
          >
            {this.props.children}
          </Dialog>
        </Content>
      </Wrapper>
    );
  }
}

export default connect(
  null,
  {
    setAppRightMargin: px => UiActions.appRightMargin.set(px)
  }
)(Modal);
