import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import AppActions from "./../../redux/actions";
import { Text } from "../Typography";
import Overlay from "../Overlay";
import Modal from "../Modal2";
import { Button } from "../Utils";
import { Input } from "../Forms";
import { Spacing } from "./../Layout";

const ButtonConfirm = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.color.positive};
    border-color: ${({ theme }) => theme.color.positive};
    margin-left: 0.5rem;
  }
`;

const Footer = styled(Spacing)`
  text-align: right;
`;

class CreateWatchlist extends React.Component {
  state = {
    name: "",
  };

  setName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleSubmit = (e) => {
    this.props.createWatchlist(this.state.name);
    this.props.hideModal();
    this.setState({
      name: "",
    });
  };

  render() {
    return this.props.modal === "create" ? (
      <Overlay>
        <Modal onClose={this.props.hideModal}>
          <Text size={2}>Create new watchlist</Text>
          <Spacing top={1} bottom={1}>
            <Input
              autoFocus={true}
              placeholder="Watchlist name"
              value={this.state.name}
              onInput={this.setName}
            />
          </Spacing>
          <Footer>
            <Button onClick={this.props.hideModal}>Cancel</Button>
            <ButtonConfirm onClick={this.handleSubmit}>Create</ButtonConfirm>
          </Footer>
        </Modal>
      </Overlay>
    ) : null;
  }
}

//connect
const mapStateToProps = (state) => {
  return {
    modal: state.ui.watchlist.modal,
  };
};

const mapDispatchToProps = {
  createWatchlist: (title) => Actions.watchlists2.createWatchlist(title),
  hideModal: () => AppActions.watchlist.hideModal(),
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateWatchlist);
