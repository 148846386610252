import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import UiActions from "./../redux/actions";
import * as Select from "./../redux/selectors";
import styled from "styled-components";
import { LikeButton } from "./Buttons";
import useItemReactions from "../hooks/useItemReactions";

const Wrapper = styled.div`
  display: flex;
  ${(props) => props.alignRight && "ustify-content: flex-end"};
  button {
    margin: 0 !important;
  }
  button + button {
    margin-left: 5px !important;
  }
`;

export default function Reactions({
  itemId,
  compact,
  alignRight,
  children,
  blockFetch,
}) {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const reactions = useItemReactions(itemId, blockFetch);
  const userReactions = useSelector(
    (state) => state.userReactions.likes[itemId]
  );
  const loggedIn = useSelector(Select.loggedIn);
  
  const handleLike = async () => {
    if (!loggedIn) {
      return dispatch(UiActions.loginOverlay.show(true));
    }

    setDisabled(true);

    if (userReactions) {
      await dispatch(Actions.userReactions.remove(userReactions.id)).then(() =>
        dispatch(Actions.feedback.addFeedback("Removed from liked", "success"))
      );
    } else {
      await dispatch(Actions.userReactions.create(itemId, "like")).then(() =>
        dispatch(Actions.feedback.addFeedback("Added to liked", "success"))
      );
    }

    setDisabled(false);
  };

  const { like: likesCounter = 0 } = reactions || {};
  const isLike = userReactions ? true : false;

  return (
    <Wrapper alignRight={alignRight}>
      {children}
      <LikeButton
        disabled={disabled}
        selected={isLike}
        count={likesCounter + (isLike ? 1 : 0)}
        onClick={handleLike}
        size={compact ? 22 : 30}
      />
    </Wrapper>
  );
}
