import React from "react";
import Modal from "./Modal2";

export default class Underlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true
    };
  }
  close = e => {
    this.setState({
      modal: false
    });
  };
  render() {
    const Page = this.props.Page;
    return [
      Page && <Page />,
      this.state.modal && <Modal onClose={this.close} {...this.props} />
    ];
  }
}
