import { css } from "styled-components";

const unit = (value, unit) => {
  if (typeof value === "number" || parseFloat(value) === value) {
    return `${value}${unit}`;
  }
  return value;
};

const px = value => unit(value, "px");

const _container = 1296;
const _collapsedWatchlist = 80;
const _largeMobile = 640;

const theme = {
  font: {
    default: `"Roboto Condensed", sans-serif`
  },
  color: {
    border: `#95a9b6`,
    text: `#135d80`,
    main: `#aabecb`,
    light: `#e0e7ec`,
    dark: `#0c273e`,
    accent: `#ecae53`,
    positive: `#008640`,
    negative: `#ff0000`,
    shadow: `rgba(0,0,0,.25)`,
    gci: `#e32c2e`
  },
  get containerWidth() {
    return px(_container);
  },
  get collapsedWatchlistWidth() {
    return px(_collapsedWatchlist);
  },
  media: {
    desktop: "(min-width: 1024px)",
    tablet: "(min-width: 769px) and (max-width: 1023px)",
    mobile: "(max-width: 768px)",
    watchlistOverlap: `(min-width:550px) and (max-width:${px(
      _container + 2 * _collapsedWatchlist
    )})`,
    showNarrowWatchlist: `(min-width: ${px(_largeMobile)})`
  },
  breakpoints: [
    { xsmall: 0 }, // all mobile devices
    { small: 576 }, // mobile devices (not sure which one's this big)
    { medium: 768 }, // ipad, ipad pro, ipad mini, etc
    { large: 1024 }, // smaller laptops
    { xlarge: 1360 } // laptops and desktops
  ]
};

export const atmedia = Object.keys(theme.media).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media ${theme.media[label]} {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

export default theme;
