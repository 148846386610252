import React, { Component } from "react";
import VoxThemeProvider from "./components/VoxThemeProvider";
import Routes from "./routes";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import { withRouter } from "react-router";
import UiActions from "./redux/actions";
import GlobalStyle from "./components/GlobalStyle";
import fetchUserData from "./fetchUserData";
import { getWindowWidth } from "./utils/window";
import { track } from "./utils/analytics";
import { throttle } from "lodash";

// import FeedbackBar from "./components/FeedbackBar";
// import LoginOverlay from "./containers/LoginOverlay";
// import MediaOverlay from "./components/MediaOverlay";
// import SubscriptionResponseHandler from "./components/SubscriptionResponseHandler";
// import LinkTracker from "./components/LinkTracker";
// import CreateWatchlistOverlay from "./components/Watchlist/WatchlistCreateOverlay";

//connect
class AppContainer extends Component {
  componentDidMount() {
    this.props.authentication().then(data => {
      if (!data.error && data.result) {
        fetchUserData(this.props.userData)
          .then(userData => {
            if (userData && userData.hasOwnProperty("id")) {
              const user_id = userData.id;
              track("login", { user_id });
            }
            let privateInvestor = window.localStorage.getItem(
              "privateInvestor"
            );
            privateInvestor !== null &&
              (userData = Object.assign(userData, { privateInvestor }));
            this.props.updateAllUserData(userData);
            this.props.setAuthenticationCompleted();
          })
          .catch(err => {
            console.log(err);
            this.props.setAuthenticationCompleted();
          });
      } else {
        this.props.setAuthenticationCompleted();
      }
    });
    this.getUser();
    this.props.isServer && this.props.setIsServer(true);
    this.props.setIsRouter(true);
    this.onAuthentication();

    //global events
    window.addEventListener("resize", this.handleResize, {
      passive: true
    });

    //init local settings
    this.props.setCurrentWatchlistId(
      window.localStorage.getItem("watchlistId")
    );
    this.props.setCollapsed(
      window.localStorage.getItem("watchlistIsCollapsed") === "false"
        ? false
        : true
    );
    this.props.setSortField(window.localStorage.getItem("sortField"));
    this.props.setSortOrder(window.localStorage.getItem("sortOrder"));

    this.props.setWindowtWidth(getWindowWidth());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      this.onAuthentication();
    }
    if (prevProps.userId !== this.props.userId) {
      this.getUser();
    }
  }

  handleResize = throttle(() => {
    this.props.setWindowtWidth(getWindowWidth());
  }, 200);

  //fetch logged user to Users and update data in userData
  getUser = () => {
    this.props.userId &&
      this.props
        .fetchUserData(this.props.userId)
        .then(
          data => data[4] && this.props.updateUserData("avatar", data[4].uri)
        )
        .catch(e => {
          console.log(`report: start user ${e}`);
        });
  };

  onAuthentication = () => {
    if (this.props.isLoggedIn) {
      this.props.fetchReactions();
      this.props.fetchWatchlists();
      this.props.fetchFollows();
    }
  };

  render() {
    const { isServer } = this.props;
    return (
      <VoxThemeProvider>
        <>
          <GlobalStyle />
          <Routes isServer={isServer}  />
        </>
      </VoxThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.authentication.loggedIn,
  userData: state.userData,
  userId: state.userData.id,
  userName: state.userData.name,
  userEmail: state.userData.email
});

const mapDispatchToProps = {
  authentication: () => Actions.authentication.loginWithWPCookieV2(),
  updateAllUserData: userData => Actions.userData.addUserDataMultiple(userData),
  updateUserData: (key, value) => Actions.userData.addUserData(key, value),
  fetchUserData: userId => Actions.users.fetchUserData(userId),
  fetchReactions: () => Actions.userReactions.fetch(),
  fetchFollows: () => Actions.follows.fetchMine(),
  setIsServer: status => UiActions.isServer.set(status),
  setIsRouter: status => UiActions.isRouter.set(status),
  fetchWatchlists: () => Actions.watchlists2.fetchWatchlists(),
  setAuthenticationCompleted: () => UiActions.authentication.setCompleted(true),
  setCollapsed: UiActions.watchlist.setCollapsed,
  setCurrentWatchlistId: UiActions.watchlist.setCurrentId,
  setSortField: UiActions.watchlist.setSortField,
  setSortOrder: UiActions.watchlist.setSortOrder,
  setWindowtWidth: UiActions.client.setWindowWidth
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppContainer)
);
