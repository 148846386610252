import { connect } from "react-redux";
import { selectWindowWidth } from "./../redux/selectors";

const Breakpoint = ({ min = 0, max = 9999, windowWidth, children }) => {
  if (windowWidth === 0) return null;

  return windowWidth > min && windowWidth <= max ? children : null;
};

export default connect(state => ({
  windowWidth: selectWindowWidth(state)
}))(Breakpoint);
