import styled, { css } from "styled-components";

export const Spacing = styled.div`
  ${props => props.all && `margin: ${props.all}rem`};

  ${props =>
    props.top &&
    `
      margin-top: ${props.top}rem;
    `};
  ${props =>
    props.bottom &&
    `
      margin-bottom: ${props.bottom}rem;
    `};
  ${props =>
    props.left &&
    `
      margin-left: ${props.left}rem;
    `};
  ${props =>
    props.right &&
    `
      margin-right: ${props.right}rem;
    `};

  ${props =>
    props.horizontal &&
    `
      margin-right: ${props.horizontal}rem;
      margin-left: ${props.horizontal}rem;
    `};

  ${props =>
    props.vertical &&
    `
      margin-top: ${props.vertical}rem;
      margin-bottom: ${props.vertical}rem;
    `};

  @media (min-width: 320px) and (max-width: 768px) {
    ${props => props.smAll !== undefined && `margin: ${props.smAll}rem`};
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    ${props => props.mdAll !== undefined && `margin: ${props.mdAll}rem`};
  }
  @media (min-width: 1025px) {
    ${props => props.lgAll !== undefined && `margin: ${props.lgAll}rem`};
  }
`;

const rwdDisplayHelper = css`
  ${props =>
    props.lgDisplay &&
    `
    display:none;
    @media (min-width: 1025px) {
      display: block;
    }   
  `};
  ${props =>
    props.smDisplay &&
    `
    display:none;
    @media (max-width: 768px) {
      display: block;
    }   
  `};
  ${props =>
    props.mdDisplay &&
    `
    display:none;
    @media (min-width: 769px) and (max-width: 1024px) {
      display: block;
    }   
  `};
`;

//remove size, switch to coll
export const Cell = styled.div`
  ${rwdDisplayHelper};
  box-sizing: inherit;
  flex-basis: 100%; /* 1 */
  min-width: ${props => (props.minWidth ? props.minWidth : 0)}; /* 2 */
  ${props => props.maxWidth && `max-width:${props.maxWidth}`}; /* 2 */
  padding: 0 calc(0.5 * 1rem);
  ${props =>
    props.spacing !== undefined &&
    `padding-bottom: ${props.spacing}rem !important`};
  ${props => props.size && sizeCell(props.size)};
  ${props => props.fit && "flex-basis: auto;"};
  ${props => props.fill && fillCell()};
  ${props => props.col && sizeCell(props.col)};
  @media (min-width: 320px) and (max-width: 768px) {
    ${props => props.smCol && sizeCell(props.smCol)};
    ${props => props.smFill && "flex: 1 1 0%;"};
    ${props => props.smFit && "flex-basis: auto;"};
    ${props =>
      props.smSpacing !== undefined &&
      `padding-bottom: ${props.smSpacing}rem !important`};
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    ${props => props.mdCol && sizeCell(props.mdCol)};
    ${props => props.mdFill && "flex: 1 1 0%;"};
    ${props => props.mdFit && "flex-basis: auto;"};
    ${props =>
      props.mdSpacing !== undefined &&
      `padding-bottom: ${props.mdSpacing}rem !important`};
  }
  @media (min-width: 1025px) {
    ${props => props.lgCol && sizeCell(props.lgCol)};
    ${props => props.lgFill && "flex: 1 1 0%;"};
    ${props => props.lgFit && "flex-basis: auto;"};
    ${props =>
      props.lgSpacing !== undefined &&
      `padding-bottom: ${props.lgSpacing}rem !important`};
  }
  @media (min-width: 1360px) {
    ${props => props.xlgCol && sizeCell(props.xlgCol)};
  }
`;


export const Grid = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: ${props => (props.noWrap ? "row" : "row wrap")};
  padding: 0;
  margin: 0 calc(-0.5 * 1rem);

  ul& {
    list-style-type: none;
  }

  ${props =>
    props.gutterSize &&
    css`
      margin: 0 calc(-0.5 * ${props => props.gutterSize}rem);
      > ${Cell} {
        padding: 0 calc(0.5 * ${props => props.gutterSize}rem);
      }
    `};
  ${props =>
    props.noGutter &&
    css`
      margin: 0;
      > ${Cell} {
        padding: 0;
      }
    `};

  ${props =>
    props.equalHeight &&
    css`
      > ${Cell} {
        display: flex;
      }
    `};

  ${props => props.center && "justify-content: center;"};
  ${props => props.right && "justify-content: flex-end;"};
  ${props => props.middle && "align-items: center;"};
  ${props => props.bottom && "align-items: flex-end;"};
  ${props => props.baseline && "align-items: baseline;"};

  > * {
    ${props => props.fill && "flex: 1 1 0%;"};
    ${props => props.fit && "flex-basis: auto;"};
  }

  @media (min-width: 320px) and (max-width: 768px) {
    > * {
      ${props => props.smFill && "flex: 1 1 0%;"};
      ${props => props.smFit && "flex-basis: auto;"};
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    > * {
      ${props => props.mdFill && "flex: 1 1 0%;"};
      ${props => props.mdFit && "flex-basis: auto;"};
    }
  }
  @media (min-width: 1025px) {
    > * {
      ${props => props.lgFill && "flex: 1 1 0%;"};
      ${props => props.lgFit && "flex-basis: auto;"};
    }
  }
`;


function fillCell() {
  return css`
    flex: 1 1 0% !important; /* 1 */
    flex-basis: 0% !important; /* 2 */
  `;
}

function sizeCell(size) {
  return {
    flexBasis: "auto !important",
    width: `${(size / 12) * 100}%`
  };
}

export const CenteredContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
// {
//   // we can define static props
//   type: "password",

//   // or we can define dynamic ones
//   margin: props => props.size || "1em",
//   padding: props => props.size || "1em"
// }

export const Box = styled.div`
  display: flex;
  height: 100%;

  ${props => props.middle && "align-items: center;"};
  ${props => props.center && "justify-content: center;"};
  ${props => props.bottom && "align-items: flex-end;"};
  ${props => props.right && "justify-content: flex-end;"};
`;

export const BoxStock = styled.div`
  display: flex;
  ${props => props.middle && "align-items: center;"};
  ${props => props.center && "justify-content: center;"};
  ${props => props.bottom && "align-items: flex-end;"};
  ${props => props.right && "justify-content: flex-end;"};
`;

export const Border = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1296px;
  padding: 0 0.5rem;
  height: 100%;
`;
