import styled, { css } from "styled-components";

// const buttonFontSize = ({ big, small, mini }) => {
//   if (big) return `font-size: 0.888rem;`;
//   if (small) return `font-size: 0.888rem;`;
//   if (mini)
//     return `font-size: 0.78rem; font-weight: 500;padding-left: 1rem; padding-right:1rem;`;
//   return `font-size: 1rem;`;
// };

const buttonColors = props => {
  if (props.primary) return `background-color: #ecae53; color: #0c273e;`;
  if (props.secondary) return `background-color: #36a1da; color: #fff;`;
  if (props.vox) return `background-color: #135d80; color: #fff;`;
  if (props.info)
    return `background-color: #ccc; color: ${props => props.theme.color.text};`;
  return `background-color: transparent; color: ${props =>
    props.theme.color.text};`;
};

//
const buttonColorsHelper = css`
  ${props =>
    props.invert &&
    `
    color: #fff;
    box-shadow: inset 0 0 0 1px #fff;   
    border-radius: 4px;
    &:hover { 
      background-color: #e0e7ec;
      color: #135d80;
      border-radius: 2px;
      }
    `};
`;

const buttonSizeHelper = css`
  ${props => props.small && `font-size: 0.888rem;`};
  ${props => props.mini && `font-size: 0.78rem; font-weight: 500;`};
`;

export const Button = styled.button`
  /* base */
  font-family: ${props => props.theme.font.default};
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #0000;
  padding: 0.275em 0.75em;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2px;

  /* extra */
  text-decoration: none;
  cursor: pointer;
  outline: none;

  ${props =>
  props.block ? "width: 100%; display: block" : "display: inline-block;"};

  ${props => buttonColors(props)};

  &:hover {
  background-color: #cccccc;
  ${props => props.primary && "background-color: #cb9647;"};
  ${props => props.secondary && "background-color: #1D88C1;"};
  ${props => props.vox && "background-color: #004467;"};
  ${props => props.info && "background-color: #B3B3B3;"};
  }
  ${buttonSizeHelper};
  ${buttonColorsHelper};
`;

export default Button;
