import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import styled from "styled-components";
import loadable from "@loadable/component";
import { Grid, Cell } from "./../Layout";
import FollowCompany from "./../../containers/CompanyFollowButton";
import CompanyLogo from "./CompanyLogo";
import CompanyTitle from "./CompanyTitle";

import BasicPrice from "./BasicPrice";
import PriceUpdate from "./PriceUpdate";
import Link from "./../CompanyPageLink";
import {
  selectCompanyByListingId,
  selectCompanyById,
} from "./../../redux/selectors";

const BasicStockChart = loadable(() => import("./../Charts/BasicStockChart"));

const ChartWrapper = styled.div`
  min-width: 100px;
  height: 70px;
`;

const PriceWrapper = styled.div`
  margin-top: 0.5rem;
  height: 70px;
  overflow: hidden;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * + * {
    margin-top: 0.5rem;
  }
`;

export const CompanyBadgeExtra = ({ listingId, company, light }) => (
  <Grid noWrap>
    <Cell fit>
      <LogoWrapper>
        <Link listingId={listingId} block>
          {company && <CompanyLogo companyId={company.id} />}
        </Link>
        <FollowCompany listingId={listingId} />
      </LogoWrapper>
    </Cell>
    <Cell fill>
      <Grid noWrap>
        <Cell fill>
          {company && (
            <Link listingId={listingId} block>
              <CompanyTitle
                listingId={listingId}
                companyId={company.id}
                whiteText={light}
              />
            </Link>
          )}
        </Cell>
        <Cell fit>
          <PriceUpdate listingId={listingId} whiteText={light} />
        </Cell>
      </Grid>
      <PriceWrapper>
        <Grid noWrap>
          <Cell fit>
            <BasicPrice listingId={listingId} whiteText={light} />
          </Cell>
          <Cell fill>
            <ChartWrapper>
              <BasicStockChart listingId={listingId} />
            </ChartWrapper>
          </Cell>
        </Grid>
      </PriceWrapper>
    </Cell>
  </Grid>
);

//-------------------
//-------------------
//-------------------

class CompanyBadgeExtraContiner extends React.Component {
  static propTypes = {
    listingId: PropTypes.string.isRequired,
    companyId: PropTypes.string,
  };

  loadCompany = () => {
    !this.props.company &&
      this.props.companyId &&
      this.props.fetchCompany(this.props.companyId);
  };

  loadListing = () => {
    !this.props.listing && this.props.fetchListing(this.props.listingId);
  };

  loadChartData = () => {
    !this.props.chartData &&
      this.props.fetchChartData(
        this.props.listingId,
        Math.floor(Date.now() / 1000), //today
        60
      );
  };

  loadSnapshot = () => {
    !this.props.snapshot &&
      this.props.fetchPrices(
        this.props.listingId,
        this.props.userHasLivePrices
      );
  };

  componentDidMount() {
    this.loadCompany();
    this.loadListing();
    //this.loadChartData();
    this.loadSnapshot();
  }

  componentDidUpdate(prevProps) {
    this.loadCompany();
    this.loadListing();
    //this.loadChartData();
    this.loadSnapshot();
  }

  render() {
    return <CompanyBadgeExtra {...this.props} />;
  }
}

const mapStateToProps = (state, { listingId, companyId }) => {
  return {
    userHasLivePrices: state.userData.livePricing === true,
    listing: state.advCompanies.listings[listingId],
    company: companyId
      ? selectCompanyById(state, companyId)
      : selectCompanyByListingId(state, listingId),
    snapshot: state.quotes[listingId],
    chartData: state.charts[listingId],
  };
};

const mapDispatchToProps = {
  fetchPrices: (listingId, showLivePrices) =>
    Actions.quotes.fetchQuotes([listingId], showLivePrices),
  fetchListing: (listingId) => Actions.advCompanies.listings.getById(listingId),
  fetchCompany: (companyId) =>
    Actions.advCompanies.companies.getById(companyId),
  fetchChartData: Actions.charts.fetchBefore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyBadgeExtraContiner);
