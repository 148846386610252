import React, { PureComponent } from "react";
import Text from "./../Text";
import { isTradingClose } from "./../../utils";
import { connect } from "react-redux";
import DateFormatter from "../../utils/Date";

class HeaderPrice extends PureComponent {
  render() {
    const { snapshot, whiteText } = this.props;
    if (snapshot) {
      const timeString = snapshot.updateTime
        ? new DateFormatter(snapshot.updateTime * 1000).formatTime()
        : "--:--";
      return (
        <>
          <Text white={whiteText} size={0.67}>
            {isTradingClose(snapshot.tradingPhase)
              ? "At close"
              : `Last updated ${timeString}`}
          </Text>
        </>
      );
    }
    return null;
  }
}

//-----------------------
//-----------------------
//-----------------------

const mapStateToProps = (state, props) => {
  return {
    snapshot: state.quotes[props.listingId]
  };
};

export default connect(mapStateToProps)(HeaderPrice);
