import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
import { Hydrate } from "react-query/hydration";
import { createStore } from "@voxmarkets/vox-reducers";

import AppReducers from "./redux/reducers";
import RealtimeData from "./utils/RealtimeData";

const queryClient = new QueryClient();

const store = createStore(
  [],
  AppReducers,
  typeof window !== "undefined" && "__PRELOADED_STATE__" in window
    ? window.__PRELOADED_STATE__
    : {},
  true
);

RealtimeData.initStore(store);

export default function AppProviders({ children }) {
  const dehydratedState = window.__REACT_QUERY_STATE__;
  
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <ReduxProvider store={store}>
          <Router>{children}</Router>
        </ReduxProvider>
      </Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
