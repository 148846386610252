import React from "react";
import styled, { css } from "styled-components";
import Text from "./Text";

const colors = props => {
  if (props.primary) return `background-color: #3a9535; color: #fff;`;
  if (props.secondary) return `background-color: #36a1da; color: #fff;`;
  if (props.vox) return `background-color: #135d80; color: #fff;`;
  if (props.info)
    return css`
      background-color: #e0e7ec;
      ${Text} {
        color: rgba(19, 93, 128, 0.7);
        font-weight: 700;
      }
    `;
  return `background-color: transparent; color: ${props =>
    props.theme.color.text};`;
};

const Wrapper = styled.span`
  display: inline-block;
  ${props => colors(props)};
  padding: 0.2em 0.4em;
  border-radius: 3px;
`;

const Badge = props => (
  <Wrapper {...props}>
    <Text size={0.7} white light nowrap>
      {props.children}
    </Text>
  </Wrapper>
);

export default Badge;
