import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: ${props => props.size / 10 || 8}px solid #f3f3f3; /* Light grey */
  border-top: ${props => props.size / 10 || 8}px solid #6b8da5; /* Blue */
  border-radius: 50%;
  width: ${props => props.size || 80}px;
  height: ${props => props.size || 80}px;
  animation: ${spin} 2s linear infinite;
  ${props => props.inline && "display:inline-block"};
  margin-left: auto;
  margin-right: auto;
`;

export default Spinner;
