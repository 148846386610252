import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import UiActions from "./../../redux/actions";
import { Input } from "../Forms";
import { SearchResult, SearchResultHeader } from "./SearchResult";
import Dismissible2 from "../Dismissible2";
import { debounce } from "../../utils";
import { Link as RouterLink } from "react-router-dom";
import * as Selectors from "./../../redux/selectors";
import Text from "../Text";

const SearchBarContainer = styled.div`
  position: relative;
  z-index: 11;
`;

const MoreLink = styled(RouterLink)`
  display: block;
  text-decoration: none;
  background-color: #135d80;
  padding: 0.25rem;
  &:hover {
    background-color: #2d779a;
  }
`;

const SearchResultContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  border: 2px solid;
  background: #fff;
  border-color: rgb(24, 94, 128);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  max-height: 80vh;
  overflow: auto;
  z-index: -1;
`;

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ""
    };
  }

  getResults = debounce(() => {
    const query = this.state.query.trim();
    if (query.length === 0) {
      this.props.resetResults();
      this.setState({ query: "" });
    }

    // only search by company name or user display name if the query string is 2 or more characters
    else if (query.length > 1) {
      this.props.search(query);
    }
  }, 500);

  reset = () => {
    this.state.query !== "" && this.props.setQuery("");
    this.setState({ query: "" });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.query !== this.state.query) {
      this.getResults();
    }
  }

  handleInput = e => {
    this.setState({ query: e.target.value });
    this.props.setQuery(e.target.value);
  };

  render = () => {

    return (
      <SearchBarContainer>
        <Dismissible2 onClose={this.reset}>
          <Input
            type="text"
            placeholder="Search Vox Markets…"
            name="searchInput"
            value={this.state.query}
            onChange={this.handleInput}
            onFocus={this.handleInput}
          />
          {this.state.query !== "" && (
            <SearchResultContainer>
              {this.props.listings.length > 0 && (
                <div onClick={this.reset}>
                  <SearchResultHeader title="Tickers" />
                  {this.props.listings
                  .sort( (a, b) => {
                    return a.listingEndDate - b.listingEndDate
                  })
                    .slice(0, 10)
                    .map(c => (
                      <SearchResult
                        key={c.listingId}
                        category="listing"
                        listingId={c.listingId}
                      />
                    ))}
                </div>
              )}
              {this.props.companies.length > 0 && (
                <div onClick={this.reset}>
                  <SearchResultHeader title="Companies" />
                  {this.props.companies
                    .slice(0, 10)
                    .map(c => (
                      <SearchResult
                        key={c.companyId}
                        companyId={c.companyId}
                        category="company"
                      />
                    ))}
                </div>
              )}
               <div>
                <MoreLink to={`/search?query=${this.state.query}`}>
                  <Text white size={0.9}>
                    {this.state.query !== "" &&
                    !this.props.companies.length &&
                    !this.props.listings.length
                      ? "Go to full search…"
                      : "See more…"}
                  </Text>
                </MoreLink>
              </div>
            </SearchResultContainer>
          )}
        </Dismissible2>
      </SearchBarContainer>
    );
  };
}

export default connect(
  state => ({
    companies: Selectors.searchCompanies(state),
    listings: Selectors.searchListings(state)
  }),
  dispatch => ({
    setQuery(q) {
      return dispatch(UiActions.search.setQuery(q));
    },
    search(query) {
      return dispatch(UiActions.search.listings(query)).then(() =>
        dispatch(UiActions.search.companies(query, 10))
      );
    },
    resetResults() {
      return dispatch(UiActions.search.resetResults());
    }
  })
)(SearchBar);
