import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import VoxTheme from "./VoxTheme";
import {
  Facebook as FacebookIco,
  Twitter as TwitterIco,
  Linkedin as LinkedInIco,
  Mail as MailIco
} from "./SocialIcons";

import { track } from "../utils/analytics";

export const ShareButton = styled.a`
  width: ${props => props.size || 40}px;
  height: ${props => props.size || 40}px;
  cursor: pointer;
  align-items: center;
  display: inline-block;
  padding: ${props => (props.size ? props.size / 6 : 4)}px;
  border: none;

  + a {
    margin-left: 5px;
  }

  svg, img {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
`;

const linkedinShare = (link, text) => {
  window.open(
    `http://www.linkedin.com/shareArticle?mini=true&url=${link}&title=${text}&source=${link}&summary=Read the full article at Vox Markets`,
    "",
    "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
  );
};

// const twitterShare = (e, url) => {
//   e.preventDefault();
//   window.open(`${url}`, "SharePopup", "left=0,top=0,height=260,width=600");
// };

export const LinkedIn = ({ link, text = "", ...props }) => (
  <ShareButton
    title="LinkedIn"
    onClick={e => {
      track("share", {
        event_action: "linkedin",
        event_label: link
      });
      linkedinShare(link, text);
    }}
    {...props}
  >
    <LinkedInIco />
  </ShareButton>
);
export const Facebook = ({ link, text = "", ...props }) => (
  <ShareButton
    title="Facebook"
    onClick={() => {
      if (typeof window !== "undefined" && window.FB)
        window.FB.ui(
          {
            method: "share",
            href: link
          },
          function(response) {}
        );
      if (props.onClickCallback) props.onClickCallback();
      track("share", {
        event_action: "facebook",
        event_label: link
      });
    }}
    {...props}
  >
    <FacebookIco />
  </ShareButton>
);

export const Twitter = ({
  link,
  text = "",
  hashtags = ["investing", "shares"],
  onClick,
  size
}) => {
    if (
      text.toLowerCase().indexOf("#voxmarkets") < 0 &&
      !hashtags.includes("voxmarkets")
  ) {
    hashtags.push("voxmarkets");
  }
  const handleClick = e => {
    const url = `//twitter.com/intent/tweet?source=${encodeURI(
      link
    )}&text=${encodeURIComponent(text)}${
      text.indexOf("voxmarkets.co.uk") === -1 ? `&url=${encodeURI(link)}` : ""
    }${text.toLowerCase().indexOf("@voxmarkets" < 0) ? "&via=voxmarkets" : ""}
      &hashtags=${encodeURIComponent([...new Set(hashtags)].join(","))}`;
    window.open(
      url,
      "",
      "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    );
    track("share", {
      event_action: "twitter",
      event_label: link
    });
    onClick && onClick(e);
  };
  return (
    <ShareButton title="Twitter" size={size} onClick={handleClick}>
      <TwitterIco />
    </ShareButton>
  );
};

export const Mail = ({ link, text = "", size, onClick }) => {
  const handleClick = e => {
    track("share", {
      event_action: "email",
      event_label: link
    });
    onClick && onClick(e);
  };
  const body = encodeURIComponent(`From Vox Markets\n${link}`);
  return (
    <ShareButton
      title="Email"
      size={size}
      onClick={handleClick}
      href={`mailto:?body=${body}&subject=${text}`}
    >
      <MailIco />
    </ShareButton>
  );
};

export const TwitterConnected = connect((state, props) => ({
  company: state.advCompanies.companies[props.companyId]
}))(Twitter);

const ShareMenuContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
export class ShareMenu extends React.PureComponent {
  render() {
    const {
      text,
      twitterText,
      facebookText,
      linkedInText,
      mailText,
      ...props
    } = this.props;
    return (
      <ShareMenuContainer>
        <Twitter text={twitterText || text} {...props} />
        <Facebook text={facebookText || text} {...props} />
        <LinkedIn text={linkedInText || text} {...props} />
        <Mail text={mailText || text} {...props} />
      </ShareMenuContainer>
    );
  }
}
