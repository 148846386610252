import React from "react";
import styled from "styled-components";

const BurgerWrapper = styled.div`
  user-select: none;
  padding-right: 0.5rem;
  cursor: pointer;
  rect {
    transition: transform 200ms ease-in-out;
  }
`;
const Burger = props => (
  <BurgerWrapper onClick={props.onClick}>
    <svg width="24" height="24" viewBox="-4 -4 34 34">
      <rect
        x="0"
        y="0"
        height="4"
        width="30"
        fill="#fff"
        style={{
          transform: props.visible ? `rotate(30deg)` : `rotate(0)`,
          transformOrigin: "2px 2px"
        }}
      />
      <rect
        x="0"
        y="13"
        height="4"
        width="30"
        fill="#fff"
        opacity={props.visible ? 0 : 1}
      />
      <rect
        x="0"
        y="26"
        height="4"
        width="30"
        fill="#fff"
        style={{
          transform: props.visible ? `rotate(-30deg)` : `rotate(0)`,
          transformOrigin: "2px 28px"
        }}
      />
    </svg>
  </BurgerWrapper>
);

const Nav = styled.nav`
  width:100%;
  max-width:280px;
  overflow: auto;
  position:absolute;
  right:0;
  top:0;
  bottom:0;
  background:#135d80;
  transition: transform 300ms ease-in-out;
  transform: ${props =>
  props.visible ? `translateX(0)` : `translateX(100%)`}; 
  `;
const NavBg = styled.div`
  position: fixed;
  top: 70px;
  right: 0;
  width: 100%;
  height: ${props => (props.visible ? `calc(100vh - 70px)` : `0`)};
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: ${props => (props.visible ? 9 : -1)};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
  ${props => !props.visible && `pointer-events:none`};
  `;

class Navburger extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: this.props.visible || false };
  }

  toggle = () => {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  };

  render() {
    return (
      <div>
        <Burger onClick={this.toggle} {...this.state}>
          
        </Burger>
        <NavBg {...this.state}>
          <Nav {...this.state}>{React.Children.map(
              this.props.children, 
              child => React.cloneElement(child, { toggle: this.toggle })
            )}</Nav>
        </NavBg>
      </div>
    );
  }
}

export default Navburger;
