// @flow
import React from "react";
import styled from "styled-components";

import { Info } from "./Icons";

import { Text } from "./Typography";
// import { Button } from "./../ui";
// import { Spacing } from "./../ui/Grid";

const Wrapper = styled.div`
  background-color: #cbd2d7;
  border: 1px solid #95a9b6;
  padding: 0.85rem 0.75rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  margin-right: 0.75rem;
`;

const Title = styled(Text)`
  margin-bottom: 0.3rem;
`;

const Content = styled.div`
  flex-grow: 1;
`;

export const AlertWrapper = ({ children, ...props}) => (
  <Wrapper {...props}>
    <Icon>
      <Info />
    </Icon>
    <Content>{children}</Content>
  </Wrapper>
)

const Alert = props => (
  <AlertWrapper>
    <Title bold size={0.89}>
      {props.title}
    </Title>
    <Text size={0.78}>{props.children}</Text>
  </AlertWrapper>
);

export default Alert;
