import styled from "styled-components";

//https://github.com/styled-components/styled-components/issues/1349

const color = props => {
  if (props.cssColor)  return props.cssColor;
  if (props.positive) return props.theme.color.positive;
  if (props.negative) return props.theme.color.negative;
  if (props.white) return `#fff;`;
  if (props.light) return `#6B8096;`;
  if (props.dark) return `#062C37;`;
  if (props.grey) return `#999;`;
  if (props.black) return `#222;`;
  if (props.theme && props.theme.color && props.theme.color.text)
    return props.theme.color.text;
  else return "black";
};

const Text = styled.div`
  font-family: ${props => (props.lato ? "Lato" : props.theme?.font?.default)},
  sans-serif;
  color: ${props => color};
  font-weight: ${ props => props.theme?.font?.weight || '400'};
  /* letter-spacing: -1px; */
  text-decoration: none;
  line-height: 1.2em;
  margin: 0;
  font-size: ${props => (props.size ? props.size : props.theme?.font?.size || 1)}rem;
  text-decoration: none;
  ${props => props.center && "text-align: center;"};
  ${props => props.left && "text-align: left;"};
  ${props => props.right && "text-align: right;"};
  ${props => props.light && "font-weight: 300;"};
  ${props => props.bold && "font-weight: 700;"};
  ${props => props.bolder && "font-weight: 900;"};
  ${props => props.nowrap && "white-space: nowrap;"};
  ${props => props.prewrap && "white-space: pre-wrap;"};
  ${props => props.preline && "white-space: pre-line;"};
  ${props => props.uppercase && "text-transform: uppercase;"};
  ${props => props.shadow && "text-shadow: 1px 1px 2px #333"};
  ${props => props.spacing && `margin-top: ${props.spacing}rem`};
  ${props => props.dotted && "border-bottom: 1px solid #bbb"};
  ${props => props.lines && `overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: ${props.lines};-webkit-box-orient: vertical;display: -webkit-box;`};
  ${props =>
    props.clip &&
  `white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`};
  `;

export default Text;
