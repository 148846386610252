import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import { isEqual } from "lodash";
import * as Selectors from "../redux/selectors";

const mapStateToProps = (state, { tickerShort }) => {
  const listing = Selectors.selectListingByTicker(state, tickerShort);
  const issue = listing
    ? Selectors.selectIssueById(state, listing.issueId)
    : null;
  return {
    issue,
    listing
  };
};

const mapDispatchToProps = {
  fetchListing: tickerShort =>
    Actions.advCompanies.listings.getByShortTicker(tickerShort),
  fetchIsues: issueId => Actions.advCompanies.issues.getById(issueId),
  fetchCompany: companyId => Actions.advCompanies.companies.getById(companyId)
};

const withCompanyIds = Component =>
  connect(mapStateToProps, mapDispatchToProps)(
    class WithCompanyIds extends React.Component {
      static propTypes = {
        tickerShort: PropTypes.string
      };

      componentDidMount() {
        !this.props.listing && this.props.fetchListing(this.props.tickerShort);
      }

      componentDidUpdate(prevProps) {
        //load
        this.props.tickerShort !== prevProps.tickerShort &&
          !this.props.listing &&
          this.props.fetchListing(this.props.tickerShort);

        //load issue
        this.props.listing &&
          this.props.listing.issueId &&
          !isEqual(this.props.listing, prevProps.listing) &&
          this.props.fetchIsues(this.props.listing.issueId);

        //load company
        this.props.issue &&
          this.props.issue.issueCompanyId &&
          !isEqual(this.props.issue, prevProps.issue) &&
          this.props.fetchCompany(this.props.issue.issueCompanyId);
      }

      render() {
        return this.props.listing && this.props.issue ? (
          <Component
            {...this.props}
            listingId={this.props.listing.id}
            issueId={this.props.listing.issueId}
            companyId={this.props.issue.issueCompanyId}
            tickerShort={this.props.tickerShort}
          />
        ) : null;
      }
    }
  );

export default withCompanyIds;
