import React from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import { format } from "date-fns";
import { Grid, Cell } from "./Layout";
import { Text } from "./Typography";
import { Img, Loader, Link } from "./Utils";

import ArticlePreview from "features/articles/components/ArticlePreview";
import MediaAsset from "features/media-assets/components/MediaAsset";
import RNSPreview from "../containers/RNSPreview";
import DocumentLink from "../components/DocumentLink";

import WithCompanyIds from "../components/withCompanyIds";
import Badge from "../components/Company/CompanyBadge";

const CompanyBadge = WithCompanyIds(props => (
  <Badge listingId={props.listingId} companyId={props.companyId} />
));

const CardWrapper = styled.div`
  margin: 10px 0;
  &:first-child {
    margin-top: 0;
  }
  padding: 10px;
  border: 1px solid ${props => (props.theme ? props.theme.color.main : "#eee")};
  ${Img} {
    max-width: 100%;
  }
  > ${Grid} {
    align-items: center;
    border-bottom: 1px solid
      ${props => (props.theme ? props.theme.color.light : "#eee")};
    margin-bottom: 5px;
  }
  iframe {
    max-width: 100%;
  }
`;

const Card = ({
  url,
  title,
  description,
  image,
  logo,
  publisher,
  date,
  embed,
  pathname
}) => (
  <CardWrapper>
    <Switch location={{ pathname }}>
      <Route
        path="/articles/:leafname"
        exact
        render={({ match }) => <ArticlePreview leafname={match.params.leafname} />}
      />
      <Route
        path="/media/:id"
        exact
        render={({ match }) => <MediaAsset mediaAssetId={match.params.id} />}
      />
      <Route
        path="/company/:ticker"
        exact
        render={({ match }) => (
          <CompanyBadge tickerShort={match.params.ticker} />
        )}
      />
      <Route
        path="/listings/:exchange/:ticker"
        exact
        render={({ match }) => (
          <CompanyBadge tickerShort={match.params.ticker} />
        )}
      />
      <Route
        path="/rns/announcement/:storyId"
        exact
        render={({ match }) => <RNSPreview storyId={match.params.storyId} />}
      />
      <Route>
        {url.toLowerCase().lastIndexOf(".pdf") === url.length - 4 ? (
          <DocumentLink displayName={ title || `Download Document`} url={url} />
        ) : (
          <Link href={url} blank>
            {(logo || publisher) && (
              <Grid>
                {logo && (
                  <Cell fit>
                    <Img src={logo} width={30} />
                  </Cell>
                )}
                {publisher && (
                  <Cell fill>
                    <Text>{publisher}</Text>
                  </Cell>
                )}
                {date && (
                  <Cell fit>
                    <Text size={0.75} light>
                      {format(new Date(date), "do MMM yyyy")}
                    </Text>
                  </Cell>
                )}
              </Grid>
            )}
            {embed && <div dangerouslySetInnerHTML={{ __html: embed }} />}
            {!embed && title && <Text bold>{title}</Text>}
            {!embed && image && <Img src={image} />}
          </Link>
        )}
      </Route>
    </Switch>
  </CardWrapper>
);

const Preview = ({ preview, url, ...props }) => (
  <div>
    {preview.loading && <Loader size={25} />}
    {preview.hasOwnProperty("meta") && <Card  url={url} {...preview.meta} />}
  </div>
);

export default Preview;
