import React from "react";
import queryString from "qs";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import { v2API } from "@voxmarkets/api-sdk";

class SubscriptionResponseHandler extends React.Component {
  constructor(props) {
    super(props);

    // enough to make an attempt?
    if (props.subAction !== undefined && props.subId !== undefined) {
      this.sendAction(props.subAction, props.subId);
    }
  }

  sendAction(subAction, subId) {
    let req;
    let successMessage;
    let failureMessage;
    if (subAction === "confirm") {
      req = v2API.Subscriptions.confirmSubscription(subId);
      successMessage = "Thanks! Your email subscription has been confirmed.";
      failureMessage =
        "Sorry, but your email subscription could not be confirmed. Please contact support.";
    } else if (subAction === "unsubscribe") {
      req = v2API.Subscriptions.confirmUnsubscribe(subId);
      successMessage =
        "You will no longer receive those emails. Thank you for letting us know.";
      failureMessage =
        "Sorry, but your request could not be processed. You may have unsubscribed already.";
    }

    // if we have an action to attempt...
    if (req !== undefined) {
      // run with it...
      req
        .then(json => {
          this.props.addFeedback(successMessage, "success");
        })
        .catch(err => {
          this.props.addFeedback(failureMessage, "error");
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    // different company?
    if (
      this.props.subAction !== nextProps.subAction &&
      this.props.subId !== nextProps.subId
    ) {
      this.sendAction(nextProps.subAction, nextProps.subId);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, props) => {
  if (typeof window !== "undefined") {
    const search = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    return {
      subAction: search.subAction,
      subId: search.subId
    };
  }
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    addFeedback: (text, status) =>
      dispatch(Actions.feedback.addFeedback(text, status))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  SubscriptionResponseHandler
);
